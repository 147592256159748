import React from 'react';
import { Translate } from '@hanssens/ht-translate';
import { ManageTable, TitleBar, NoContent } from 'ht-gui';

const NoPresenceGroups = (props) => {
    const { groups } = props;

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const config = {
        columns: {
            name: {
                label: <Translate id='groupMembership.emptyGroup.overview.name' />,
            },
            number: {
                label: <Translate id='groupMembership.emptyGroup.overview.number' />,
            },
        },
        hideSearch: true,
        noItemsComponent: (
            <NoContent
                title={<Translate id='groupMembership.emptyGroup.overview.noPresence.noEntries' />}
                description={<Translate id='groupMembership.emptyGroup.overview.noPresence.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-users-slash fa-7x'
            />
        ),
        data: groups,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    return (
        <div className='mt-3'>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='groupMembership.emptyGroup.overview.noPresence.title' />} />
            </div>
            <ManageTable {...config} />
        </div>
    );
};

export default NoPresenceGroups;
