import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const SidebarLeftItem = ({ link, label, icon, hoverIcon, exact, onClick }) => {
    const [hovered, setHovered] = useState(false);
    const location = useLocation();

    /********************************
     * Event handlers
     ********************************/
    const handleMouseEntered = () => {
        setHovered(true);
    };

    const handleMouseLeft = () => {
        setHovered(false);
    };

    const handleLinkClicked = (event) => {
        event.preventDefault();
        onClick(link);
    };

    /********************************/
    /* RENDER 
    /********************************/
    // Create the base class names
    let classnames = ['nav-item', 'nav-item-content', 'd-flex', 'align-items-center'];

    // Add additional classnames based on if the current link is active
    if (exact) {
        if (location.pathname === link) {
            classnames.push('bg-secondary', 'text-white', 'active');
        }
    } else {
        if (location.pathname.startsWith(link)) {
            classnames.push('bg-secondary', 'text-white', 'active');
        }
    }

    let iconClassNames = '';
    if (hovered && hoverIcon) {
        iconClassNames = hoverIcon;
    } else {
        iconClassNames = icon;
    }

    return (
        <li onClick={() => onClick(link)} className={classnames.join(' ')} onMouseEnter={handleMouseEntered} onMouseLeave={handleMouseLeft}>
            <a href={link} className='sidebar-navigation-list-item text-white pl-3 w-100 h-100 d-flex align-items-center' onClick={handleLinkClicked}>
                <i className={iconClassNames} />
                <span className='text-capitalize disable-text-highlighting'>{label}</span>
            </a>
        </li>
    );
};

export default SidebarLeftItem;
