import React, { useState } from 'react';

import SidebarLeftItem from './SidebarLeftItem';
import SidebarLeftTitle from './SidebarLeftTitle';

const SidebarLeftItems = ({ first, config, onClick, title }) => {
    /********************************/
    /* LOCAL STATE
    /********************************/
    const [toggled, setToggled] = useState(true);

    /********************************/
    /* RENDER 
    /********************************/
    let items = [];
    if (toggled) {
        items = config.map((item, index) => {
            if (item.component) {
                let Component = item.component;
                return <Component key={index} {...item} />;
            }
            return <SidebarLeftItem key={index} {...item} onClick={onClick} />;
        });
    }

    return (
        <>
            {/* Title */}
            <SidebarLeftTitle first={first} toggled={toggled} title={title} onClick={() => setToggled(!toggled)} />

            {/* Items */}
            {items}
        </>
    );
};

export default SidebarLeftItems;
