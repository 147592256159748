import React, { useEffect, useState } from 'react';
import { SuggestiveSelect, PageTitle } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { Outlet, useNavigate } from 'react-router';
import { useStore } from 'ht-store';

import groupMembershipApi from '../../../api/groupMembershipApi';
import { routerPrefix } from '../../../routes/MainRoutes';

const Users = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [users, setUsers] = useState(null);
    const [userNumber, setUserNumber] = useState(null);
    const [, setUser] = useStore('groupMembership-user');
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        groupMembershipApi()
            .get('v1/pbxuser')
            .then((resp) => {
                let data = resp.data.map((d) => {
                    let label = `(${d.number})`;

                    if (d.firstName && d.lastName) {
                        label = `${d.firstName} ${d.lastName} (${d.number})`;
                    } else if (d.lastName && !d.firstName) {
                        label = `${d.lastName} (${d.number})`;
                    }

                    return {
                        value: d.number,
                        label: label,
                        obj: d,
                    };
                });
                setUsers(data);
            })
            .catch((err) => {
                console.log(err);
                setError(err);
            });
    }, []);

    useEffect(() => {
        if (users) {
            let data = users.find((us) => us.value === userNumber);
            if (!data) {
                return;
            }
            setUser(data.obj);
        }
    }, [userNumber, users, setUser]);

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const handleUserChanged = (e, v) => {
        if (!v) {
            setUserNumber(v);
            navigate(routerPrefix + '/supervisor/users', { replace: true });
        } else {
            setUserNumber(v);
            navigate(v);
        }
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let label = (
        <>
            <Translate id='groupMembership.user.search.loading' />
            <i className='fas fa-spinner-third fa-spin ml-2' />
        </>
    );

    if (error) {
        label = <Translate id='groupMembership.user.search.error' />;
    } else if (users && users.length > 0) {
        label = <Translate id='groupMembership.user.search.title' />;
    }

    return (
        <section id='users' className='p-4'>
            <PageTitle title={<Translate id='groupMembership.user.title' />} subTitle={<Translate id='groupMembership.user.subTitle' />} />
            <div className='bg-white box-shadow p-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4'>
                        <SuggestiveSelect value={userNumber} options={users ?? []} onChange={handleUserChanged} label={label} id='search-user' disabled={!users} />
                    </div>
                </div>
            </div>

            <Outlet context={{ setUserNumber: setUserNumber }} />
        </section>
    );
};

export default Users;
