import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import withResize from 'ht-resize';
import { useStore } from 'ht-store';
import { useTranslate } from '@hanssens/ht-translate';

// GENERAL
import EmptyGroups from '../pages/General/EmptyGroups/EmptyGroups';
import Report from '../pages/General/Report/Report';
import UserRoutes from './UserRoutes';
import GroupRoutes from './GroupRoutes';
import AuthRoute from '../components/Routers/AuthRoute';
import PrivateTenantRoute from '../components/Routers/PrivateTenantRoute';
import AppSettingRoute from '../components/Routers/AppSettingRoute';

import SingleUserRoute from '../pages/General/SingleUser/SingleUser';

// Translations
import globalTranslations from '../translations/global.json';

// Css
import '../css/main.scss';

export const routerPrefix = '/groupmembership';

const MainRoutes = () => {
    /***********************************************************************
     * State
    /***********************************************************************/
    useTranslate(globalTranslations);

    const [auth] = useStore('auth');

    /***********************************************************************
     * Render
    /***********************************************************************/

    let routes = [];
    if (auth && auth.rights && auth.rights.length > 0) {
        let redirectUrl = null;
        if (auth.rights.includes('groupMembership.singleUser.access') || auth.rights.includes('*')) {
            routes.push(<Route key='device' path='/device/*' element={<SingleUserRoute />} />);
            // routes.push(<Route key='redirect' path='*' element={<Navigate to='/groupmembership/device' replace />} />);

            if (!redirectUrl) {
                redirectUrl = '/groupmembership/device';
            }
        }
        // else {

        if (auth.rights.includes('groupMembership.users.access') || auth.rights.includes('*')) {
            routes.push(<Route key='users' path='/supervisor/users/*' element={<UserRoutes />} />);
            if (!redirectUrl) {
                redirectUrl = '/groupmembership/supervisor/users';
            }
        }

        if (auth.rights.includes('groupMembership.groups.access') || auth.rights.includes('*')) {
            routes.push(<Route key='groups' path='/supervisor/groups/*' element={<GroupRoutes />} />);
            if (!redirectUrl) {
                redirectUrl = '/groupmembership/supervisor/groups';
            }
        }

        if (auth.rights.includes('groupMembership.emptyGroups.access') || auth.rights.includes('*')) {
            routes.push(<Route key='emptygroups' path='/supervisor/emptygroups' element={<EmptyGroups />} />);
            if (!redirectUrl) {
                redirectUrl = '/groupmembership/supervisor/emptygroups';
            }
        }

        if (auth.rights.includes('groupMembership.reports.access') || auth.rights.includes('*')) {
            routes.push(<Route key='reports' path='/supervisor/reports' element={<Report />} />);
            if (!redirectUrl) {
                redirectUrl = '/groupmembership/supervisor/reports';
            }
        }

        if (redirectUrl) {
            routes.push(<Route key='redirect' path='*' element={<Navigate to={redirectUrl} replace />} />);
        }
        // }
    }
    return (
        <AuthRoute>
            <PrivateTenantRoute>
                <AppSettingRoute>
                    <div className='main-scrollable'>
                        <Routes>{routes.map((r) => r)}</Routes>
                    </div>
                </AppSettingRoute>
            </PrivateTenantRoute>
        </AuthRoute>
    );
};

export default withResize()(MainRoutes);
