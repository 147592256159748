import React, { useEffect, useRef } from 'react';
import { NoContent } from 'ht-gui';

const Error = ({ code, message, className, timeout, noWrapper, icon }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const intervalReference = useRef(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        if (intervalReference.current) {
            clearInterval(intervalReference.current);
        }

        intervalReference.current = setTimeout(() => {
            return null;
        }, timeout);
    }, [timeout]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    if (Array.isArray(code) && code.length > 0) {
        let iconClassNames = ['fas', 'fa-7x'];

        switch (code[0]) {
            case 503:
                iconClassNames.push('fa-cloud-slash');
                break;

            default:
                iconClassNames.push('fa-bug');
                break;
        }

        let codes = [...new Set(code)].map((c) => {
            return c;
        });

        let codesContent = codes.map((c) => `${c}${codes.length > 1 ? ',' : ''}`);
        let messagesContent = message;

        if (Array.isArray(message) && message.length > 0) {
            messagesContent = message.map((m, index) => {
                if (m)
                    return (
                        <>
                            {`(${code[index]}) ${m}`}
                            <br />
                        </>
                    );
                return null;
            });
        }

        return (
            <div className={className}>
                <NoContent title={codesContent} description={messagesContent} icon={icon ?? iconClassNames.join(' ')} backgroundColour='bg-danger' noWrapper={noWrapper} />
            </div>
        );
    } else {
        let iconClassNames = ['fas', 'fa-7x'];

        switch (code) {
            case 503:
                iconClassNames.push('fa-cloud-slash');
                break;

            default:
                iconClassNames.push('fa-bug');
                break;
        }

        return (
            <div className={className}>
                <NoContent title={code} description={message} icon={icon ?? iconClassNames.join(' ')} backgroundColour='bg-danger' noWrapper={noWrapper} />
            </div>
        );
    }
};

export default Error;
