import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { getClasses } from 'ht-resize';
import { SaveButton, TitleBar } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';

import { default as initial, right, left } from './UserModal/dragAndDropData';

import UserDragNDrop from './UserModal/UserDragNDrop';
import groupMembershipApi from '../../../api/groupMembershipApi';

const UserModal = ({ title, userIds, failed, confirmLabel, titleBarColor, confirmClasses, confirmingLabel, onClose, onSave, group }) => {
    const [error, setError] = useState(false);
    const [users, setUsers] = useState(initial);
    const [saving, setSaving] = useState(false);

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleSaveClicked = (onSuccess, onError) => {
        setSaving(true);
        setError(false);

        let groupData = [];

        // Add new ones
        users.columns[right.id].itemIds.forEach((i) => {
            if (!userIds.includes(i)) {
                let item = users.items.find((g) => g.number === i);

                if (item) {
                    let data = {
                        addToGroup: true,
                        presence: 1,
                        number: item.number,
                        ringGroup: group.number,
                        userName: `${item.firstName} ${item.lastName}`,
                        userNumber: item.number,
                        ringGroupName: group.name,
                    };

                    groupData.push(data);
                }
            }
        });

        // Remove old ones
        users.columns[left.id].itemIds.forEach((i) => {
            if (userIds.includes(i)) {
                let item = users.items.find((g) => g.number === i);

                if (item) {
                    let data = {
                        presence: 1,
                        number: item.number,
                        ringGroup: group.number,
                        userName: `${item.firstName} ${item.lastName}`,
                        userNumber: item.number,
                        ringGroupName: group.name,
                    };

                    groupData.push(data);
                }
            }
        });

        groupMembershipApi()
            .post('v1/userGroupMember', groupData)
            .then((resp) => {
                let data = resp.data.map((d) => {
                    return {
                        ...d,
                        memberInfo: users.items.find((u) => u.number === d.number),
                    };
                });
                onSave(data);
                setSaving(false);
                onSuccess();
            })
            .catch((err) => {
                console.log('Error: ', err);
                onError();
                setSaving(false);
                setError(true);
            });
    };

    const handleCancelClicked = () => {
        onClose();
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    if (!titleBarColor) {
        titleBarColor = 'bg-primary';
    }

    let saveButtonClasses = [];
    if (!confirmClasses) {
        saveButtonClasses = ['bg-primary', 'text-white'];
    } else {
        if (Array.isArray(confirmClasses)) {
            saveButtonClasses = [...confirmClasses];
        } else {
            saveButtonClasses = [confirmClasses];
        }
    }

    return (
        <Modal
            open
            onClose={onClose}
            aria-labelledby='groupmembership-users'
            aria-describedby='Groupmembership users popup'
            className='d-flex align-items-center justify-content-center'>
            <div
                id='group-membership-users-popup'
                className={getClasses({ shared: 'd-flex flex-column bg-white outline-0', xs: 'w-75', md: 'w-50' })}>
                {/* Title */}
                <TitleBar
                    color={titleBarColor}
                    label={title}
                    componentsAfter={
                        <div className='ml-auto d-flex align-items-center justify-content-center clickable' onClick={onClose}>
                            <i className='fas fa-times'></i>
                        </div>
                    }
                />

                {/* Description */}
                <UserDragNDrop userIds={userIds} users={users} setUsers={setUsers} saving={saving} />

                {error ? <div className='alert alert-danger rounded-0 mx-3'>{failed}</div> : null}

                <div className='mt-auto ml-auto mr-3 mb-3'>
                    <SaveButton
                        showFeedback
                        onSave={handleSaveClicked}
                        onCancel={handleCancelClicked}
                        saveContent={confirmLabel ? confirmLabel : <Translate id='groupMembership.group.confirm' />}
                        saveClasses={saveButtonClasses.join(' ')}
                        savingContent={confirmingLabel ? confirmingLabel : <Translate id='groupMembership.group.saving' />}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default UserModal;
