import { Translate } from '@hanssens/ht-translate';

export const left = {
    id: 'left',
    title: <Translate id='groupMembership.user.overview.allGroups' />,
    itemIds: [],
};

export const right = {
    id: 'right',
    title: <Translate id='groupMembership.user.overview.allowedGroups' />,
    itemIds: [],
};

const entities = {
    columns: {
        [left.id]: left,
        [right.id]: right,
    },
    items: null,
};

export default entities;
