import React, { useEffect, useState } from 'react';
import { SuggestiveSelect, PageTitle } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { Outlet, useNavigate } from 'react-router';
import { useStore } from 'ht-store';

import groupMembershipApi from '../../../api/groupMembershipApi';
import { routerPrefix } from '../../../routes/MainRoutes';

const Groups = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [groups, setGroups] = useState(null);
    const [groupNumber, setGroupNumber] = useState(null);
    const [, setGroup] = useStore('groupMembership-group');
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        groupMembershipApi()
            .get('v1/group')
            .then((resp) => {
                let data = resp.data.map((d) => {
                    let label = `(${d.number})`;

                    if (d.name) {
                        label = `${d.name} (${d.number})`;
                    }

                    return {
                        value: d.number,
                        label: label,
                        obj: d,
                    };
                });
                setGroups(data);
            })
            .catch((err) => {
                console.log(err);
                setError(err);
            });
    }, []);

    useEffect(() => {
        if (groups) {
            let data = groups.find((us) => us.value === groupNumber);
            if (!data) {
                return;
            }
            setGroup(data.obj);
        }
    }, [groupNumber, groups, setGroup]);

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const handleGroupChanged = (e, v) => {
        if (!v) {
            setGroupNumber(v);
            navigate(routerPrefix + '/supervisor/groups', { replace: true });
        } else {
            setGroupNumber(v);
            navigate(v);
        }
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let label = (
        <>
            <Translate id='groupMembership.group.search.loading' />
            <i className='fas fa-spinner-third fa-spin ml-2' />
        </>
    );

    if (error) {
        label = <Translate id='groupMembership.group.search.error' />;
    } else if (groups && groups.length > 0) {
        label = <Translate id='groupMembership.group.search.title' />;
    }

    return (
        <section id='groups' className='p-4'>
            <PageTitle title={<Translate id='groupMembership.group.title' />} subTitle={<Translate id='groupMembership.group.subTitle' />} />
            <div className='bg-white box-shadow p-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4'>
                        <SuggestiveSelect value={groupNumber} options={groups ?? []} onChange={handleGroupChanged} label={label} id='search-group' disabled={!groups} />
                    </div>
                </div>
            </div>

            <Outlet context={{ setGroupNumber: setGroupNumber, groupNumber: groupNumber }} />
        </section>
    );
};

export default Groups;
