import React from 'react';
import { useStore } from 'ht-store';

import Unauthorized from './AuthRoute/Unauthorized';

const AuthRoute = ({ children }) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [auth] = useStore('auth');

    /***********************************************************************
     * Render
    /***********************************************************************/
    return !auth?.error ? children : <Unauthorized className='m-4' />;
};

export default AuthRoute;
