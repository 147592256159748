import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';

import userManager from '../../../configs/userManager';

const CallbackPage = () => {
    const navigate = useNavigate();

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={(user) => {
                navigate(user.state.redirectUrl, { replace: true });
            }}
            errorCallback={(error) => {
                navigate('/unauthorized', { replace: true });
            }}>
            <div>Redirecting...</div>
        </CallbackComponent>
    );
};

export default CallbackPage;
