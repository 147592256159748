import React from 'react';
import { PageTitle } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';

import SingleUserOverview from '../../../components/SingleUser/SingleUserOverview';

import userTranslations from '../../../translations/user/user.json';

const SingleUser = (props) => {
    useTranslate(userTranslations);
    return (
        <section id='users' className='p-4'>
            <PageTitle title={<Translate id='groupMembership.singleUser.title' />} subTitle={<Translate id='groupMembership.singleUser.subTitle' />} />

            <SingleUserOverview />
        </section>
    );
};

export default SingleUser;
