import React, { useEffect, useState, useCallback } from 'react';
import { ManageTable, TitleBar, NoContent, DateTimePicker, controlTypes, Search, sortOrder } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { routerPrefix } from '../../routes/MainRoutes';

import getQuery from '../../utilities/query';
import groupMembershipApi from '../../api/groupMembershipApi';
import Loading from '../Shared/Loading';

let cancelTokenSource = axios.CancelToken.source();

const initialData = {
    count: 0,
    items: null,
};

const ReportOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [actionLogs, setActionLogs] = useState(initialData);

    const location = useLocation();
    const navigate = useNavigate();

    // Default times
    const today = moment.utc().set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
    const aWeekAgo = moment.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'week');

    const query = getQuery(location, [
        { name: 'search', defaultValue: '' },
        { name: 'page', defaultValue: '1' }, // page count starts in uri at 1, while for manage table at 0 (see code for manage table)
        { name: 'rows', defaultValue: '25' },
        { name: 'sortParam', defaultValue: 'actionDate' },
        { name: 'order', defaultValue: 'desc' },
        { name: 'start', defaultValue: aWeekAgo.format('YYYY-MM-DD HH:mm') },
        { name: 'end', defaultValue: today.format('YYYY-MM-DD HH:mm') },
    ]);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchData = useCallback((search, page, rows, sortParam, order, start, end) => {
        // Create a new cancel token
        cancelTokenSource = axios.CancelToken.source();

        groupMembershipApi()
            .get(
                'v1/actionlog?search=' +
                    encodeURIComponent(search) +
                    '&page=' +
                    page +
                    '&rowsPerPage=' +
                    rows +
                    '&sortParam=' +
                    sortParam +
                    '&order=' +
                    order +
                    '&start=' +
                    start +
                    '&end=' +
                    end,
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((response) => {
                setActionLogs(response.data);
            })
            .catch((error) => {
                console.log('Actionlog overview error', error, error.response);
            });
    }, []);

    useEffect(() => {
        const currentQuery = getQuery(location, [
            { name: 'search', defaultValue: '' },
            { name: 'page', defaultValue: '1' }, // page count starts in uri at 1, while for manage table at 0 (see code for manage table)
            { name: 'rows', defaultValue: '25' },
            { name: 'sortParam', defaultValue: 'actionDate' },
            { name: 'order', defaultValue: 'desc' },
            {
                name: 'start',
                defaultValue: moment.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'week').format('YYYY-MM-DD HH:mm'),
            },
            { name: 'end', defaultValue: moment.utc().set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm') },
        ]);

        cancelTokenSource.cancel('Operation cancelled due to new request.');

        fetchData(
            currentQuery.data.search,
            currentQuery.data.page,
            currentQuery.data.rows,
            currentQuery.data.sortParam,
            currentQuery.data.order,
            currentQuery.data.start,
            currentQuery.data.end
        );
    }, [location, fetchData]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const changeQuery = useCallback(
        (searchValue, pageValue, rowValue, sortParamValue, sortAscValue, start, end) => {
            navigate(
                routerPrefix +
                    '/supervisor/reports?search=' +
                    encodeURIComponent(searchValue.replaceAll('%', '')) +
                    '&page=' +
                    pageValue +
                    '&rows=' +
                    rowValue +
                    '&sortParam=' +
                    sortParamValue +
                    '&sortAsc=' +
                    sortAscValue +
                    '&start=' +
                    start +
                    '&end=' +
                    end,
                { replace: true }
            );
        },
        [navigate]
    );

    // This code is copyright 2012 by Gavin Kistner, !@phrogz.net
    // It is covered under the license viewable at http://phrogz.net/JS/_ReuseLicense.txt
    // Faster sort
    const sortArray = (_array, f) => {
        if (!Array.isArray(_array)) {
            return _array;
        }

        let array = [..._array];

        for (let i = array.length; i; ) {
            let o = array[--i];
            array[i] = [].concat(f.call(o, o, i), o);
        }

        array.sort((a, b) => {
            for (let j = 0, len = a.length; j < len; j++) {
                if (a[j] !== b[j]) {
                    return a[j] < b[j] ? -1 : 1;
                }
            }

            return 0;
        });

        for (let n = array.length; n; ) {
            array[--n] = array[n][array[n].length - 1];
        }

        return array;
    };

    const handleStartDateChanged = useCallback(
        (date) => {
            if ((date && !moment(date).isValid()) || !date) {
                return;
            }

            changeQuery(
                query.data.search,
                query.data.page,
                query.data.rows,
                query.data.sortParam,
                query.data.sortAsc,
                date && moment(date).isValid() ? date.format('YYYY-MM-DD HH:mm') : '',
                query.data.end
            );
        },
        [query, changeQuery]
    );

    const handleEndDateChanged = useCallback(
        (date) => {
            if ((date && !moment(date).isValid()) || !date) {
                return;
            }

            changeQuery(
                query.data.search,
                query.data.page,
                query.data.rows,
                query.data.sortParam,
                query.data.sortAsc,
                query.data.start,
                date && moment(date).isValid() ? date.format('YYYY-MM-DD HH:mm') : ''
            );
        },
        [query, changeQuery]
    );

    const handleSearchChanged = useCallback(
        (value) => {
            changeQuery(value, 1, query.data.rows, query.data.sortParam, query.data.sortAsc, query.data.start, query.data.end);
        },
        [query, changeQuery]
    );

    const handlePageChanged = useCallback(
        (event, page) => {
            // increment page by one: manage table pages start at 0, while page count in uri (query.data.page) start at 1
            changeQuery(query.data.search, page + 1, query.data.rows, query.data.sortParam, query.data.sortAsc, query.data.start, query.data.end);
        },
        [query, changeQuery]
    );

    const handleRowsPerPageChanged = useCallback(
        (event) => {
            // Get current properties
            let currentRowsPerPage = Number(query.data.rows);
            let currentPage = Number(query.data.page);
            let index = (currentPage - 1) * currentRowsPerPage;

            // Calculate desired page
            let desiredRowsPerPage = Number(event.target.value);
            let desiredPage = Math.floor(index / desiredRowsPerPage) + 1;

            changeQuery(
                query.data.search,
                desiredPage,
                desiredRowsPerPage,
                query.data.sortParam,
                query.data.sortAsc,
                query.data.start,
                query.data.end
            );
        },
        [query, changeQuery]
    );

    const handleSortChanged = useCallback(
        (sortBy) => {
            changeQuery(query.data.search, query.data.page, query.data.rows, sortBy.by, sortBy.order === 'ASC', query.data.start, query.data.end);
        },
        [changeQuery, query]
    );

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/
    let content = (
        <Loading
            title={<Translate id='groupMembership.report.overview.loading' />}
            description={<Translate id='groupMembership.report.overview.loadingDesc' />}
        />
    );

    let data = actionLogs.items?.map((al) => {
        // Reformat the display of the value
        let value = al.newValue;

        if (al.oldValue) {
            value = `${al.oldValue} -> ${al.newValue}`;
        }

        // Get the name of the executor
        let executor = al.userName;
        if (al.supervisorId && !al.supervisor) {
            executor = al.supervisorId;
        } else if (al.supervisor) {
            executor = `${al.supervisor.name} ${al.supervisor.surname}`;
        }

        // Reformat the name of the user
        let user = al.userName;

        if (al.userNumber !== '') {
            if (user === '') {
                user = al.userNumber;
            } else {
                user += ` (${al.userNumber})`;
            }
        }

        // Format the execution date
        let date = moment.utc(al.actionDate).format('DD/MM/yyyy (HH:mm)');

        return {
            actionName: al.action.name,
            user: user,
            value: value,
            executor: executor,
            actionDate: date,
        };
    });

    if (data) {
        data = sortArray(data, (d) => {
            return -new Date(d.actionDate);
        });
    }

    const config = {
        columns: {
            actionDate: {
                label: <Translate id='groupMembership.report.overview.date' />,
            },
            user: {
                label: <Translate id='groupMembership.report.overview.userName' />,
            },
            actionName: {
                label: <Translate id='groupMembership.report.overview.actionName' />,
            },
            value: {
                label: <Translate id='groupMembership.report.overview.value' />,
            },
            executor: {
                label: <Translate id='groupMembership.report.overview.executor' />,
            },
        },
        noItemsComponent: (
            <NoContent
                title={<Translate id='groupMembership.report.overview.noEntries' />}
                description={<Translate id='groupMembership.report.overview.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-file-slash fa-7x'
            />
        ),
        search: {},
        type: controlTypes.CONTROLLED,
        pagination: {
            page: Number(query.data.page) - 1,
            count: actionLogs.count,
            rowsPerPage: Number(query.data.rows),
            rowsPerPageOptions: [25, 50, 100],
            onChangePage: handlePageChanged,
            onChangeRowsPerPage: handleRowsPerPageChanged,
        },
        sortBy: {
            by: 'actionDate',
            order: sortOrder.desc,
            hideInactiveSort: true,
            onChange: handleSortChanged,
        },
        data: data,
    };

    if (actionLogs.items) {
        content = <ManageTable {...config} />;
    }

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    return (
        <div className=''>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='groupMembership.report.overview.title' />} />
            </div>
            <Search
                id='actionLog.search'
                label={<Translate id='groupMembership.report.overview.search' />}
                value={decodeURI(decodeURIComponent(query.data.search))}
                onSearchChange={handleSearchChanged}
                componentsAfter={
                    <div className='d-flex flex-row ml-2'>
                        <DateTimePicker
                            id='date-time-picker-start'
                            value={moment(query.data.start).isValid() ? moment(query.data.start) : null}
                            onChange={handleStartDateChanged}
                            label={<Translate id='groupMembership.report.overview.startTime' />}
                            withError={true}
                            error={false}
                            errorMessage='test'
                            className='mr-1'
                        />
                        <DateTimePicker
                            id='date-time-picker-end'
                            value={moment(query.data.end).isValid() ? moment(query.data.end) : null}
                            onChange={handleEndDateChanged}
                            label={<Translate id='groupMembership.report.overview.endTime' />}
                            withError={true}
                            error={false}
                            errorMessage='test'
                            className='ml-1'
                        />
                    </div>
                }
            />
            {content}
        </div>
    );
};

export default ReportOverview;
