import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';

import Callback from '../components/Auth/Callback';
import UnAuthorized from '../components/Auth/UnAuthorized';
import PrivateRoute from '../components/Auth/PrivateRoute';
import App from '../pages/App';

import loginTranslations from '../translations/auth/login';

const AuthRoutes = (props) => {
    useTranslate(loginTranslations);

    return (
        <Routes>
            <Route path='/callback' element={<Callback />} />
            <Route path='/logout' element={<UnAuthorized />} />
            <Route path='/unauthorized' element={<UnAuthorized />} />
            <Route path='*' element={<PrivateRoute component={App} />} />
        </Routes>
    );
};

export default AuthRoutes;
