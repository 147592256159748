import React, { useEffect, useState } from 'react';
import { PageTitle } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';

import Loading from '../../../components/Shared/Loading';
import Error from '../../../components/Shared/Error';
import EmptyGroupsOverview from '../../../components/EmptyGroups/EmptyGroupOverview';
import NoPresenceGroups from '../../../components/EmptyGroups/NoPresenceGroups';

import groupMembershipApi from '../../../api/groupMembershipApi';
import emptyGroupTranslations from '../../../translations/emptyGroup/emptyGroup.json';

// Css
import '../../../css/EmptyGroups/emptyGroups.scss';

const EmptyGroups = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [emptyGroups, setEmptyGroups] = useState(null);
    const [error, setError] = useState(null);

    useTranslate(emptyGroupTranslations);

    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        groupMembershipApi()
            .get('v1/group/na/emptyGroups')
            .then((resp) => {
                setEmptyGroups(resp.data);
            })
            .catch((err) => {
                console.log(err);
                setError(err);
            });
    }, []);

    /***********************************************************************
     * Return
    /***********************************************************************/

    let content = <Loading title={<Translate id='groupMembership.emptyGroup.loading' />} description={<Translate id='groupMembership.emptyGroup.loadingDesc' />} />;

    if (error) {
        content = <Error code={<Translate id='groupMembership.emptyGroup.error' />} message={<Translate id='groupMembership.emptyGroup.errorDesc' />} />;
    }

    if (emptyGroups && !error) {
        content = (
            <>
                <EmptyGroupsOverview groups={emptyGroups.filter((eg) => !eg.members)} />
                <NoPresenceGroups groups={emptyGroups.filter((eg) => eg.members)} />
            </>
        );
    }

    return (
        <section id='empty-groups' className='p-4 scrollable-y'>
            <PageTitle title={<Translate id='groupMembership.emptyGroup.title' />} subTitle={<Translate id='groupMembership.emptyGroup.subTitle' />} />
            {content}
        </section>
    );
};

export default EmptyGroups;
