import React from 'react';

import * as types from './TopbarDropdown/DropdownTypes';

import DropDownButton from './TopbarRight/DropDownButton';

const TopbarRight = (props) => {
    let classNames = ['sidebar-right-toggle', 'fas', 'fa-caret-left', 'mx-auto', 'text-white', 'fa-2x'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    return (
        <div className='bl-md item d-none d-lg-flex ml-auto align-items-start'>
            {/* <DropDownButton
                handleTopbarMenuClicked={props.handleTopbarMenuClicked}
                type={types.DROPDOWN_NOTIFICATIONS}
                active={props.dropdown}
                colour='notifications'
                icon='far fa-bell'
                text='text-danger'
            />
            <DropDownButton handleTopbarMenuClicked={props.handleTopbarMenuClicked} type={types.DROPDOWN_FAVOURITES} active={props.dropdown} colour='profile' icon='fas fa-th' text='text-primary' /> */}
            <DropDownButton
                handleTopbarMenuClicked={props.handleTopbarMenuClicked}
                type={types.DROPDOWN_PROFILE}
                active={props.dropdown}
                colour='favourites'
                icon='fas fa-user-circle'
                text='text-dark'
            />
            {/* <div onClick={props.handleToggleRightClicked} className='bg-primary item-right ml-auto d-flex align-items-center clickable'>
                <i className={classNames.join(' ')}></i>
            </div> */}
        </div>
    );
};

export default TopbarRight;
