import { Translate } from '@hanssens/ht-translate';
import { TitleBar } from 'ht-gui';
import React, { useEffect, useState } from 'react';
import { useStore } from 'ht-store';

import groupMembershipApi from '../../api/groupMembershipApi';
import UserGroups from './UserGroups';

import { default as initial, left, right } from './UserGroups/dragAndDropData';

const SingleUserOverview = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [groups, setGroups] = useState(initial);
    const [error, setError] = useState(false);

    const [user, setUser] = useStore('groupMembership-singleUser');

    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        if (!user) {
            groupMembershipApi()
                .get(`v1/deviceme`)
                .then((resp) => {
                    setUser(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [user, setUser]);

    useEffect(() => {
        groupMembershipApi()
            .get(`v1/devicegroup`)
            .then((resp) => {
                let data = {
                    items: resp.data ? resp.data : [], // data is '' empty string if there are no groups
                    columns: {
                        ...initial.columns,
                        [left.id]: {
                            ...initial.columns.left,
                            itemIds: resp.data ? resp.data.filter((g) => g.members && g.members.length > 0 && g.members[0].presence !== 0).map((g) => g.number) : [],
                        },
                        [right.id]: {
                            ...initial.columns.right,
                            itemIds: resp.data ? resp.data.filter((g) => g.members && g.members.length > 0 && g.members[0].presence === 0).map((g) => g.number) : [],
                        },
                    },
                };

                setGroups(data);
            })
            .catch((err) => {
                setError(true);
                console.log(err);
            });
    }, []);

    /***********************************************************************
     * Render
    /***********************************************************************/
    let titleLabel = <Translate id='groupMembership.user.overview.title' />;
    let outboundNumberContent = null;

    if (user) {
        titleLabel = (
            <>
                <Translate id='groupMembership.user.overview.title' />: {user.number}
            </>
        );

        if (user.lastName && user.firstName) {
            titleLabel = (
                <>
                    <Translate id='groupMembership.user.overview.title' />: {user.firstName} {user.lastName}
                </>
            );
        }

        if (user.outboundNumber && groups.items) {
            let group = groups.items.find((g) => g.didNumber && g.didNumber.number === user.outboundNumber);
            if (group) {
                outboundNumberContent = `${user.outboundNumber} - ${group.name}`;
            } else {
                outboundNumberContent = `${user.outboundNumber} - (*)  ${user?.name} ${user?.lastName}`;
            }
        } else if (user.outboundNumber) {
            outboundNumberContent = `${user.outboundNumber} - (*)  ${user?.name} ${user?.lastName}`;
        } else {
            outboundNumberContent = (
                <>
                    <i className='fas fa-times text-danger mx-1' />
                    <Translate id='groupMembership.user.overview.noOutboundNumber' />
                </>
            );
        }
    }

    let data = {
        ...groups,
        items: groups.items?.map((d) => {
            return {
                ...d,
                checked: d.didNumber && d.didNumber.number === user?.outboundNumber,
            };
        }),
    };

    return (
        <div className='mt-3'>
            <div className='box-shadow'>
                <TitleBar label={titleLabel} />
                <UserGroups groups={data} setGroups={setGroups} user={user} error={error} />
            </div>
            <div className='user-outbound mt-3 box-shadow'>
                <TitleBar label={<Translate id='groupMembership.user.overview.choosenOutboundNumber' />} />
                <div className='bg-white p-3'>
                    <Translate id='groupMembership.user.overview.outboundNumber' />: {outboundNumberContent}
                </div>
            </div>
        </div>
    );
};

export default SingleUserOverview;
