import React from 'react';

const SidebarRightTitle = ({ toggled, first, title, onClick }) => {
    /********************************/
    /* RENDER 
    /********************************/
    // Initialise the class names
    let classnames = ['fas fa-caret-left fa-2x'];

    // Add toggled
    if (!toggled) {
        classnames.push('toggled');
    }

    // Create list classes
    let listClasses = ['nav-item nav-item-title d-flex align-items-center pl-3 clickable'];
    if (!first) {
        listClasses.push('mt-3');
    }

    return (
        <li onClick={onClick} className={listClasses.join(' ')}>
            <span className='text-uppercase'>{title}</span>
            <div className='ml-auto mr-3 d-flex align-items-center justify-content-center' style={{ width: 22.75 }}>
                <i className={classnames.join(' ')} style={{ width: 20 }}></i>
            </div>
        </li>
    );
};

export default SidebarRightTitle;
