import React from 'react';
import { useTranslate } from '@hanssens/ht-translate';
import { SidebarLeft as GroupMembershipSidebarLeft } from '../../../library/index';

// Import components
import SidebarLeftItems from '../../components/Layout/SidebarLeft/SidebarLeftItems';

// Import translation file
import sidebarLeftTranslations from '../../translations/layout/sidebarLeft.json';

// Import css
import './SidebarLeft.scss';

// Import logo
import logo from '../../assets/images/Hanssens_beyondtelecom_white.svg';

const SidebarLeft = (props) => {
    /********************************/
    /* LOCAL STATE & PROPS
    /********************************/
    // Add the translation file
    useTranslate(sidebarLeftTranslations);

    /********************************/
    /* RENDER
    /********************************/
    // Set classnames
    let classNames = ['bg-dark-blue', 'text-white', 'br-sm', 'b-secondary'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    return (
        <div id='sidebar-left' className={classNames.join(' ')}>
            <div className='sidebar-left-container'>
                <ul className='nav nav-pils flex-column'>
                    <GroupMembershipSidebarLeft first onClick={props.handleLinkClicked} container={SidebarLeftItems} />
                </ul>
            </div>
            <a href='https://hanssenstelecom.be/contact/' target='_blank' rel='noreferrer'>
                <div className='logo mt-auto w-100 d-flex pb-3 justify-content-center'>
                    <img className='img-fluid' src={logo} alt='hanssens' />
                </div>
            </a>
        </div>
    );
};

export default SidebarLeft;
