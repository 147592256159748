import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';

export const snackbarReset = {
    open: false,
    text: '',
    severity: 'error',
};

const SnackbarHandler = (props) => {
    const [snackbar, setSnackbar] = useStore('snackbarHandler-snackbar');

    /***********************************************************************
     * Startup
     ***********************************************************************/
    useEffect(() => {
        setSnackbar(snackbarReset);
    }, [setSnackbar]);

    /***********************************************************************
     * Render
     ***********************************************************************/
    if (!snackbar) {
        return null;
    }

    return (
        <Snackbar className={props.className} open={snackbar.open} autoHideDuration={4000} onClose={() => setSnackbar(snackbarReset)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            {snackbar.open && snackbar.text && snackbar.text !== '' ? (
                <Alert onClose={() => setSnackbar(snackbarReset)} severity={snackbar.severity}>
                    {snackbar.translate === false ? snackbar.text : <Translate id={snackbar.text} />}
                </Alert>
            ) : null}
        </Snackbar>
    );
};

export default SnackbarHandler;
