import React from 'react';
import * as types from './TopbarDropdown/DropdownTypes';

import Profile from './TopbarDropdown/Profile';
// import Notifications from './TopbarDropdown/Notifications';
// import Favourites from './TopbarDropdown/Favourites';

const TopbarDropdown = (props) => {
    let content = null;
    let classNames = ['bl-sm', 'bb-sm', 'b-secondary', 'bg-white'];

    if (props.toggled) {
        classNames.push('open');
    }

    switch (props.dropdown) {
        case types.DROPDOWN_NOTIFICATIONS:
            // content = <Notifications />;
            // classNames.push("notifications");
            break;
        case types.DROPDOWN_FAVOURITES:
            // content = <Favourites />;
            // classNames.push("favourites");
            break;
        case types.DROPDOWN_PROFILE:
            content = <Profile />;
            classNames.push('profile');
            break;
        default:
            content = null;
            break;
    }

    return (
        <section id='topbar-dropdown' className={classNames.join(' ')}>
            {content}
        </section>
    );
};

export default TopbarDropdown;
