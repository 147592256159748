import React from 'react';
import { NoContent } from 'ht-gui';

const Loading = ({ title, description, className, backgroundColor, noWrapper }) => {
    return (
        <div className={className}>
            <NoContent title={title} description={description} icon='fas fa-spin fa-spinner-third fa-7x' backgroundColour={backgroundColor ? backgroundColor : 'bg-primary'} noWrapper={noWrapper} />
        </div>
    );
};

export default Loading;
