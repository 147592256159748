export const storeConfig = {
    'groupMembership-draggingGroup': {
        initialValue: null,
        persist: false,
    },
    'groupMembership-draggingUser': {
        initialValue: null,
        persist: false,
    },
    'groupMembership-user': {
        initialValue: null,
        persist: false,
    },
    'groupMembership-singleUser': {
        initialValue: null,
        persist: false,
    },
};
