import React from 'react';

const MiddleButtons = (props) => {
    const handleAddClicked = (e) => {
        if (props.disabled) {
            e.preventDefault();
            return;
        }

        props.onAddClicked(e);
    };

    const handleRemoveClicked = (e) => {
        if (props.disabled) {
            e.preventDefault();
            return;
        }

        props.onRemoveClicked(e);
    };

    let classNamesTop = ['group-button mb-1 p-2 rounded-circle d-flex justify-content-center align-items-middle'];
    let classNamesBottom = ['group-button mt-1 p-2 rounded-circle d-flex justify-content-center align-items-middle'];

    if (!props.disabled) {
        classNamesTop.push('bg-primary clickable');
        classNamesBottom.push('bg-primary clickable');
    } else {
        classNamesTop.push('disabled');
        classNamesBottom.push('disabled');
    }

    return (
        <div className='h-100 d-flex justify-content-center'>
            <div id='group-buttons' className='h-100 d-flex flex-column px-3 justify-content-center'>
                <div className={classNamesTop.join(' ')} onClick={handleAddClicked}>
                    <i className='fas fa-caret-right fa-2x text-white' />
                </div>
                <div className={classNamesBottom.join(' ')} onClick={handleRemoveClicked}>
                    <i className='fas fa-caret-left fa-2x text-white' />
                </div>
            </div>
        </div>
    );
};

export default MiddleButtons;
