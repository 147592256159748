import React from 'react';
import { useStore } from 'ht-store';

import SelectATenant from './PrivateTenantRoute/SelectATenant';

const PrivateTenantRoute = ({ children }) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [tenant] = useStore('tenant');

    /***********************************************************************
     * Render
    /***********************************************************************/
    return tenant?.selectedTenant ? children : <SelectATenant className='m-4' />;
};

export default PrivateTenantRoute;
