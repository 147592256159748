import React, { useEffect, useState } from 'react';
import { PercentageColumn, Search } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { DragDropContext } from 'react-beautiful-dnd';

import MiddleButtons from '../../../Users/UserGroups/MiddleButtons';

import { multiDragAwareReorder, multiSelectTo as multiSelect } from '../../../../utilities/dragAndDropUtils';
import { default as initial, left, right } from './dragAndDropData';
import groupMembershipApi from '../../../../api/groupMembershipApi';

import UsersContainer from './UsersContainer';
import Loading from '../../../Shared/Loading';

const UserDragNDrop = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { userIds, users, setUsers, saving } = props;
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filter, setFilter] = useState('');

    const [draggingUserId, setDraggingUserId] = useState(null);

    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        groupMembershipApi()
            .get('v1/pbxuser')
            .then((resp) => {
                setUsers({
                    items: resp.data,
                    columns: {
                        ...initial.columns,
                        [left.id]: { ...initial.columns.left, itemIds: resp.data.filter((d) => !userIds.includes(d.number)).map((g) => g.number) },
                        [right.id]: { ...initial.columns.right, itemIds: resp.data.filter((d) => userIds.includes(d.number)).map((g) => g.number) },
                    },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [userIds, setUsers]);

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const handleAddClicked = (e) => {
        if (selectedUsers.length > 0) {
            let index = users.columns[left.id].itemIds.findIndex((i) => i === selectedUsers[0]);
            // Left to right
            let result = {
                source: {
                    index: index,
                    droppableId: left.id,
                },
                destination: {
                    index: 0,
                    droppableId: right.id,
                },
                reason: 'DROP',
            };
            handleDragEnd(result);
        }
    };

    const handleRemoveClicked = (e) => {
        if (selectedUsers.length > 0) {
            let index = users.columns[right.id].itemIds.findIndex((i) => i === selectedUsers[0]);
            // Right to left
            let result = {
                source: {
                    index: index,
                    droppableId: right.id,
                },
                destination: {
                    index: 0,
                    droppableId: left.id,
                },
                reason: 'DROP',
            };
            handleDragEnd(result);
        }
    };

    // ------------------------- //
    //  Drag events              //
    // ------------------------- //

    const handleDragStart = (item) => {
        // if (saving) {
        //     return;
        // }

        if (!draggingUserId) {
            setDraggingUserId(item.draggableId);
        }

        if (!selectedUsers.includes(item.draggableId)) {
            setSelectedUsers([...selectedUsers, item.draggableId]);
        }
    };

    const handleDragEnd = (result) => {
        const destination = result.destination;
        const source = result.source;

        // nothing to do
        if (!destination || result.reason === 'CANCEL') {
            setDraggingUserId(null);
            return;
        }

        setDraggingUserId(null);
        const processed = multiDragAwareReorder({
            entities: { ...users },
            selectedItemIds: selectedUsers,
            source,
            destination,
        });

        setUsers(processed.entities);
        setSelectedUsers(processed.selectedItemIds);
    };

    const toggleSelectionInGroup = (itemId) => {
        const selectedItemIds = [...selectedUsers];
        const index = selectedItemIds.indexOf(itemId);

        // if not selected - add it to the selected items
        if (index === -1) {
            setSelectedUsers([...selectedItemIds, itemId]);
            return;
        }

        // it was previously selected and now needs to be removed from the group
        const shallow = [...selectedItemIds];
        shallow.splice(index, 1);
        setSelectedUsers(shallow);
    };

    const toggleSelection = (itemId) => {
        const selectedItemIds = [...selectedUsers];
        const wasSelected = selectedItemIds.includes(itemId);

        const newItemIds = (() => {
            // Item was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [itemId];
            }

            // Item was part of a selected group
            // will now become the only selected item
            if (selectedItemIds.length > 1) {
                return [itemId];
            }

            // Item was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        setSelectedUsers(newItemIds);
    };

    // This behaviour matches the MacOSX finder selection
    const multiSelectTo = (newItemId) => {
        const updated = multiSelect({ ...users }, [...selectedUsers], newItemId);

        if (updated == null) {
            return;
        }

        setSelectedUsers(updated);
    };

    const handleFilterChanged = (value) => {
        setFilter(value);
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let content = (
        <div className='d-flex justify-content-center p-3'>
            <Loading title={<Translate id='groupMembership.group.users.loading' />} description={<Translate id='groupMembership.group.users.loadingDesc' />} noWrapper />
        </div>
    );

    if (users.items) {
        content = (
            <div className='d-flex users-container px-3 pb-3'>
                <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                    <PercentageColumn width='45'>
                        <UsersContainer
                            title={users.columns[left.id].title}
                            users={users}
                            selectedUsers={selectedUsers}
                            id={left.id}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            filter={filter}
                            disabled={saving}
                        />
                    </PercentageColumn>
                    <PercentageColumn width='10'>
                        <MiddleButtons onAddClicked={handleAddClicked} onRemoveClicked={handleRemoveClicked} disabled={saving} />
                    </PercentageColumn>
                    <PercentageColumn width='45'>
                        <UsersContainer
                            title={users.columns[right.id].title}
                            users={users}
                            selectedUsers={selectedUsers}
                            id={right.id}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            filter={filter}
                            disabled={saving}
                        />
                    </PercentageColumn>
                </DragDropContext>
            </div>
        );
    }

    return (
        <>
            <div className='px-3 pt-3'>
                <Search id='users-search' label={<Translate id='groupMembership.group.users.search' />} boxClassName='pb-0' onSearchChange={handleFilterChanged} />
            </div>
            {content}
        </>
    );
};

export default UserDragNDrop;
