import React, { useState } from 'react';
import { PercentageColumn } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import MiddleButtons from './UserGroups/MiddleButtons';
import UserGroupsContainer from './UserGroups/UserGroupsContainer';
import Loading from '../../components/Shared/Loading';
import GroupModal from './UserGroups/GroupModal';

import { multiDragAwareReorder, multiSelectTo as multiSelect } from '../../utilities/dragAndDropUtils';
import { default as initial, left, right } from './UserGroups/dragAndDropData';
import groupMembershipApi from '../../api/groupMembershipApi';

const UserGroups = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { groups, setGroups } = props;
    const { number } = useParams();

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [saving, setSaving] = useState(false);
    const [showPopup, setShowPopup] = useState(null);

    const [draggingGroupId, setDraggingGroupId] = useStore('groupMembership-draggingGroup');
    const [user] = useStore('groupMembership-user');
    const [, setSnackbar] = useStore('snackbarHandler-snackbar');

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const handleAddClicked = (e) => {
        if (selectedGroups.length > 0) {
            let index = groups.columns[left.id].itemIds.findIndex((i) => i === selectedGroups[0]);
            // Left to right
            let result = {
                source: {
                    index: index,
                    droppableId: left.id,
                },
                destination: {
                    index: 0,
                    droppableId: right.id,
                },
                reason: 'DROP',
            };
            handleDragEnd(result);
        }
    };

    const handleRemoveClicked = (e) => {
        if (selectedGroups.length > 0) {
            let index = groups.columns[right.id].itemIds.findIndex((i) => i === selectedGroups[0]);
            // Right to left
            let result = {
                source: {
                    index: index,
                    droppableId: right.id,
                },
                destination: {
                    index: 0,
                    droppableId: left.id,
                },
                reason: 'DROP',
            };
            handleDragEnd(result);
        }
    };

    const updateUserGroupMember = (userGroup) => {
        return new Promise((resolve, reject) => {
            groupMembershipApi()
                .put(`v1/userGroupMember/${userGroup.memberIndex}`, userGroup)
                .then((resp) => {
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        });
    };

    const updateUserGroupMembers = (userGroups) => {
        return new Promise((resolve, reject) => {
            groupMembershipApi()
                .post(`v1/userGroupMember`, userGroups)
                .then((resp) => {
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        });
    };

    const updateGroupsForUser = (groups, selectedGroups, source, destination) => {
        return new Promise((resolve, reject) => {
            // Move from left to right
            // And clap your hands
            if (source.droppableId === left.id && destination.droppableId === right.id) {
                // Multiple
                if (selectedGroups.length > 1) {
                    let dataToSend = [];
                    groups.items.forEach((g) => {
                        if (selectedGroups.includes(g.number)) {
                            dataToSend.push({
                                ...g.members[0],
                                presence: 0,
                                changeAvailability: true,
                                ringGroupName: g.name,
                                userName: `${user.firstName} ${user.lastName}`,
                            });
                        }
                    });

                    updateUserGroupMembers(dataToSend)
                        .then((resp) => {
                            resolve();
                        })
                        .catch((err) => {
                            reject();
                        });
                } else if (selectedGroups.length === 1) {
                    // Single
                    let group = groups.items.find((g) => g.number === selectedGroups[0]);

                    if (!group) {
                        reject();
                    }

                    let dataToSend = {
                        ...group.members[0],
                        presence: 0,
                        changeAvailability: true,
                        ringGroupName: group.name,
                        userName: `${user.firstName} ${user.lastName}`,
                    };

                    updateUserGroupMember(dataToSend)
                        .then((resp) => {
                            resolve();
                        })
                        .catch((err) => {
                            reject();
                        });
                } else {
                    reject();
                }
            }
            // Move from right to left
            else if (source.droppableId === right.id && destination.droppableId === left.id) {
                // Multiple
                if (selectedGroups.length > 1) {
                    let dataToSend = [];
                    groups.items.forEach((g) => {
                        if (selectedGroups.includes(g.number)) {
                            dataToSend.push({
                                ...g.members[0],
                                presence: 1,
                                changeAvailability: true,
                                ringGroupName: g.name,
                                userName: `${user.firstName} ${user.lastName}`,
                            });
                        }
                    });

                    updateUserGroupMembers(dataToSend)
                        .then((resp) => {
                            resolve();
                        })
                        .catch((err) => {
                            reject();
                        });
                } else if (selectedGroups.length === 1) {
                    // Single
                    let group = groups.items.find((g) => g.number === selectedGroups[0]);

                    if (!group) {
                        reject();
                    }

                    let dataToSend = {
                        ...group.members[0],
                        presence: 1,
                        changeAvailability: true,
                        ringGroupName: group.name,
                        userName: `${user.firstName} ${user.lastName}`,
                    };

                    updateUserGroupMember(dataToSend)
                        .then((resp) => {
                            resolve();
                        })
                        .catch((err) => {
                            reject();
                        });
                } else {
                    reject();
                }
            } else {
                console.error('An error occured during the save of the groups');
                reject();
            }
        });
    };

    // ------------------------- //
    //  Drag events              //
    // ------------------------- //

    const handleDragStart = (item) => {
        if (saving) {
            return;
        }

        if (!draggingGroupId) {
            setDraggingGroupId(item.draggableId);
        }

        if (!selectedGroups.includes(item.draggableId)) {
            setSelectedGroups([...selectedGroups, item.draggableId]);
        }
    };

    const handleDragEnd = (result) => {
        const destination = result.destination;
        const source = result.source;

        // nothing to do
        if (!destination || result.reason === 'CANCEL') {
            setDraggingGroupId(null);
            return;
        }

        if (selectedGroups.length > 1) {
            let inUse = false;
            selectedGroups.forEach((element) => {
                let d = groups.items.find((g) => g.number === element);

                if (d.didNumber && d.didNumber.number === user?.outboundNumber) {
                    inUse = true;
                    return;
                }
            });

            if (inUse) {
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.numberInUse' />,
                    severity: 'warning',
                    translate: false,
                });
                setDraggingGroupId(null);
                return;
            }
        } else if (selectedGroups.length === 1) {
            let d = groups.items.find((g) => g.number === selectedGroups[0]);

            if (d.didNumber && d.didNumber.number === user?.outboundNumber) {
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.numberInUse' />,
                    severity: 'warning',
                    translate: false,
                });
                setDraggingGroupId(null);
                return;
            }
        }

        setDraggingGroupId(null);
        setSaving(true);
        const processed = multiDragAwareReorder({
            entities: { ...groups },
            selectedItemIds: selectedGroups,
            source,
            destination,
        });

        let items = processed.entities.items.map((i) => {
            let presence = 1;

            if (processed.entities.columns[right.id].itemIds.includes(i.number)) {
                presence = 0;
            }

            return {
                ...i,
                members: [
                    {
                        ...i.members[0],
                        presence: presence,
                    },
                ],
            };
        });

        let entities = {
            ...processed.entities,
            items: items,
        };

        setGroups(entities);
        setSelectedGroups(processed.selectedItemIds);

        updateGroupsForUser(groups, selectedGroups, source, destination)
            .then(() => {
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.saved' />,
                    severity: 'success',
                    translate: false,
                });
                setSaving(false);
            })
            .catch(() => {
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.errorGroupSave' />,
                    severity: 'error',
                    translate: false,
                });

                // Revert changes
                const processedRevert = multiDragAwareReorder({
                    entities: { ...entities },
                    selectedItemIds: selectedGroups,
                    source: destination,
                    destination: source,
                });

                let items = processedRevert.entities.items.map((i) => {
                    let presence = 1;

                    if (processedRevert.entities.columns[right.id].itemIds.includes(i.number)) {
                        presence = 0;
                    }

                    return {
                        ...i,
                        members: [
                            {
                                ...i.members[0],
                                presence: presence,
                            },
                        ],
                    };
                });

                let oldEntities = {
                    ...processedRevert.entities,
                    items: items,
                };

                setGroups(oldEntities);
                setSelectedGroups(processedRevert.selectedItemIds);

                setSaving(false);
                return;
            });
    };

    const toggleSelectionInGroup = (itemId) => {
        const selectedItemIds = [...selectedGroups];
        const index = selectedItemIds.indexOf(itemId);

        // if not selected - add it to the selected items
        if (index === -1) {
            setSelectedGroups([...selectedItemIds, itemId]);
            return;
        }

        // it was previously selected and now needs to be removed from the group
        const shallow = [...selectedItemIds];
        shallow.splice(index, 1);
        setSelectedGroups(shallow);
    };

    const toggleSelection = (itemId) => {
        const selectedItemIds = [...selectedGroups];
        const wasSelected = selectedItemIds.includes(itemId);

        const newItemIds = (() => {
            // Item was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [itemId];
            }

            // Item was part of a selected group
            // will now become the only selected item
            if (selectedItemIds.length > 1) {
                return [itemId];
            }

            // Item was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        setSelectedGroups(newItemIds);
    };

    // This behaviour matches the MacOSX finder selection
    const multiSelectTo = (newItemId) => {
        const updated = multiSelect({ ...groups }, [...selectedGroups], newItemId);

        if (updated == null) {
            return;
        }

        setSelectedGroups(updated);
    };

    // ------------------------- //
    //  Popup events             //
    // ------------------------- //

    const handleGroupsSaved = (resp) => {
        if (resp.data && resp.data.length > 0) {
            groupMembershipApi()
                .get(`v1/group/${number}/ringGroups`)
                .then((resp) => {
                    let data = {
                        items: resp.data,
                        columns: {
                            ...initial.columns,
                            [left.id]: {
                                ...initial.columns.left,
                                itemIds: resp.data
                                    .filter((g) => g.members && g.members.length > 0 && g.members[0].presence !== 0)
                                    .map((g) => g.number),
                            },
                            [right.id]: {
                                ...initial.columns.right,
                                itemIds: resp.data
                                    .filter((g) => g.members && g.members.length > 0 && g.members[0].presence === 0)
                                    .map((g) => g.number),
                            },
                        },
                    };

                    setGroups(data);
                    setShowPopup(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setShowPopup(false);
        }
    };

    /***********************************************************************
     * Render
    /***********************************************************************/
    if (!groups.items) {
        return (
            <Loading
                title={<Translate id='groupMembership.user.overview.groups.loading' />}
                description={<Translate id='groupMembership.user.overview.groups.loadingDesc' />}
            />
        );
    }

    let popupContent = null;
    if (showPopup) {
        popupContent = (
            <GroupModal
                title={
                    <>
                        <Translate id='groupMembership.user.groups.title' />: {user?.firstName} {user?.lastName}
                    </>
                }
                userGroupIds={groups.items.map((i) => i.number)}
                failed={<Translate id='groupMembership.user.groups.failed' />}
                titleBarColor='bg-primary'
                confirmClasses={['bg-success', 'text-white']}
                onClose={() => setShowPopup(null)}
                onSave={handleGroupsSaved}
            />
        );
    }

    return (
        <>
            {popupContent}

            <div id='user-groups' className='w-100 bg-white d-flex p-3'>
                <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                    <PercentageColumn width='45'>
                        <UserGroupsContainer
                            title={groups.columns[left.id].title}
                            groups={groups}
                            selectedGroups={selectedGroups}
                            id={left.id}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            disabled={saving}
                            componentsAfter={
                                <div className='ml-auto'>
                                    <Translate id='groupMembership.user.overview.editGroups' />{' '}
                                    <i onClick={() => setShowPopup(true)} className='ml-2 fas fa-gears clickable text-primary' />
                                </div>
                            }
                        />
                    </PercentageColumn>
                    <PercentageColumn width='10'>
                        <MiddleButtons onAddClicked={handleAddClicked} onRemoveClicked={handleRemoveClicked} disabled={saving} />
                    </PercentageColumn>
                    <PercentageColumn width='45'>
                        <UserGroupsContainer
                            title={groups.columns[right.id].title}
                            groups={groups}
                            selectedGroups={selectedGroups}
                            id={right.id}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            disabled={saving}
                        />
                    </PercentageColumn>
                </DragDropContext>
            </div>
        </>
    );
};

export default UserGroups;
