import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import UserGroupItem from './UserGroupItem';

const UserGroupsContainer = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { groups, selectedGroups, id, toggleSelectionInGroup, toggleSelection, multiSelectTo, disabled } = props;
    const [saving, setSaving] = useState(false);

    /***********************************************************************
     * Render
    /***********************************************************************/
    if (!groups) {
        return 'no groups';
    }

    let content = groups.columns[id].itemIds.map((itemId, index) => {
        let g = groups.items.find((g) => g.number === itemId);
        let found = selectedGroups.findIndex((sg) => sg === g.number) !== -1;

        return (
            <Draggable key={g.number} draggableId={g.number} index={index} isDragDisabled={disabled}>
                {(provided, snapshot) => (
                    <UserGroupItem
                        containerId={id}
                        provided={provided}
                        snapshot={snapshot}
                        group={g}
                        found={found}
                        selectionCount={selectedGroups.length}
                        toggleSelectionInGroup={toggleSelectionInGroup}
                        toggleSelection={toggleSelection}
                        multiSelectTo={multiSelectTo}
                        setSaving={setSaving}
                        saving={saving}
                    />
                )}
            </Draggable>
        );
    });

    return (
        <div className='h-100 d-flex flex-column'>
            <div className='d-flex flex-row mb-1'>
                {props.title}
                {props.componentsAfter}
            </div>

            <Droppable key={id} droppableId={id}>
                {(provided) => (
                    <div className='bg-light h-100 scrollable p-2' ref={provided.innerRef} {...provided.droppableProps}>
                        {content}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default UserGroupsContainer;
