import React from 'react';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';

import { routerPrefix } from '../../routes/MainRoutes';

import sidebarLeftTranslations from '../../translations/layout/sidebarLeft.json';

const SidebarLeft = ({ first, container, onClick }) => {
    /********************************/
    /* LOCAL STATE & PROPS
    /********************************/
    // Add the translation file
    useTranslate(sidebarLeftTranslations);
    const [auth] = useStore('auth');

    /********************************/
    /* RENDER
    /********************************/
    // Define the links
    /*
     * Each array is basically a 'category'.
     * The items in a category are a 3-wide array:
     * - The first element is the link
     * - The second element is the name (or the translation key)
     * - The third element is the icon to display
     */
    const generalLinks = [];

    if (auth && auth.rights && auth.rights.length > 0) {
        if (auth.rights.includes('groupMembership.singleUser.access') || auth.rights.includes('*')) {
            generalLinks.push({
                link: `${routerPrefix}/device`,
                label: <Translate id='groupMembership.sidebarLeft.PORTAL' />,
                icon: 'fal fa-user',
                hoverIcon: 'fas fa-user',
            });
        }
        // } else {
        if (auth.rights.includes('groupMembership.users.access') || auth.rights.includes('*')) {
            generalLinks.push({
                link: `${routerPrefix}/supervisor/users`,
                label: <Translate id='groupMembership.sidebarLeft.USERS' />,
                icon: 'fal fa-user',
                hoverIcon: 'fas fa-user',
            });
        }

        if (auth.rights.includes('groupMembership.groups.access') || auth.rights.includes('*')) {
            generalLinks.push({
                link: `${routerPrefix}/supervisor/groups`,
                label: <Translate id='groupMembership.sidebarLeft.GROUPS' />,
                icon: 'fal fa-user-group',
                hoverIcon: 'fas fa-user-group',
            });
        }

        if (auth.rights.includes('groupMembership.emptyGroups.access') || auth.rights.includes('*')) {
            generalLinks.push({
                link: `${routerPrefix}/supervisor/emptygroups`,
                label: <Translate id='groupMembership.sidebarLeft.EMPTYGROUPS' />,
                icon: 'fal fa-users',
                hoverIcon: 'fas fa-users',
            });
        }

        if (auth.rights.includes('groupMembership.reports.access') || auth.rights.includes('*')) {
            generalLinks.push({
                link: `${routerPrefix}/supervisor/reports`,
                label: <Translate id='groupMembership.sidebarLeft.REPORTS' />,
                icon: 'fal fa-folder-magnifying-glass',
                hoverIcon: 'fas fa-folder-magnifying-glass',
            });
        }
        // }
    }

    let SidebarItemsContainer = container;
    return <SidebarItemsContainer first={first} config={generalLinks} onClick={onClick} title={<Translate id='groupMembership.sidebarLeft.title' />} />;
};

export default SidebarLeft;
