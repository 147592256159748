import React from 'react';
import { Switch } from 'ht-gui';

const SwitchWrapper = ({ checked, onChange, data, disabled }) => {
    if (checked === null) {
        return null;
    }
    return <Switch checked={checked} disabled={disabled} onChange={(e) => onChange(e, data)} />;
};

export default SwitchWrapper;
