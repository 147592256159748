const getQuery = (location, requestedProperties = []) => {
    // Init query
    let query = null;

    // Set query
    if (location && location.search) {
        query = new URLSearchParams(location.search);
    }

    // Create the result
    let result = {
        query: query,
        data: {},
    };

    // Loop over the properties
    if (requestedProperties && requestedProperties.length > 0) {
        requestedProperties.forEach((requestedProperty) => {
            let value = requestedProperty.defaultValue;
            if (query && query.has(requestedProperty.name)) {
                value = query.get(requestedProperty.name);
            }

            result.data[requestedProperty.name] = value;
        });
    }

    return result;
};

export default getQuery;
