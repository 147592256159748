import { Translate } from '@hanssens/ht-translate';
import { TitleBar, ManageTable, NoContent } from 'ht-gui';
import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import groupMembershipApi from '../../api/groupMembershipApi';
import SwitchWrapper from '../Shared/Managetable/SwitchWrapper';
import UserModal from './GroupOverview/UserModal';
import Loading from '../Shared/Loading';

const GroupOverview = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { number } = useParams();
    const { groupNumber, setGroupNumber } = useOutletContext();

    const [savingPresence, setSavingPresence] = useState(false);
    const [savingOutbound, setSavingOutbound] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [group, setGroup] = useState(null);

    /***********************************************************************
    /* Init
    /***********************************************************************/
    useEffect(() => {
        if (!groupNumber) {
            setGroupNumber(number);
        }
    }, [groupNumber, number, setGroupNumber]);

    useEffect(() => {
        if (number) {
            setGroup(null);
            groupMembershipApi()
                .get(`v1/group/${number}/ringGroup`)
                .then((resp) => {
                    let d = {
                        ...resp.data,
                        members: resp.data.members ?? [],
                    };
                    setGroup(d);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [number]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleEditUsersClicked = (e) => {
        setShowPopup(true);
    };

    const handlePresenceChanged = (e, data) => {
        setSavingPresence(true);

        let dataToSend = {
            homeElement: data.homeElement,
            memberIndex: data.memberIndex,
            name: data.name,
            number: data.number,
            presence: data.presence === 0 ? 1 : 0,
            ringGroup: data.ringGroup,
            secondaryElement: '',
            userName: `${data.memberInfo.firstName} ${data.memberInfo.lastName}`,
            ringGroupName: group.name,
        };

        groupMembershipApi()
            .put(`v1/usergroupmember/${data.memberIndex}`, dataToSend)
            .then((resp) => {
                let updatedGroup = { ...group };
                let index = updatedGroup.members.findIndex((m) => m.number === data.number);

                if (index !== -1) {
                    updatedGroup.members[index] = {
                        ...updatedGroup.members[index],
                        presence: resp.data.presence,
                    };

                    setGroup(updatedGroup);
                }

                setSavingPresence(false);
            })
            .catch((err) => {
                console.log(err);
                setSavingPresence(false);
            });
    };

    const handleOutboundChanged = (e, data) => {
        setSavingOutbound(true);

        let dataToSend = {
            associatedType: 7,
            associatedNumber: e.target.checked ? group.didNumber.number : data.memberInfo.inboundNumber,
            billingNumber: '',
            directoryNumber: data.number,
            username: data.username,
            userNumber: data.number,
            oldValue: data.memberInfo.outboundNumber,
        };

        groupMembershipApi()
            .put(`v1/associatedNumber/${data.memberInfo.number}`, dataToSend)
            .then((resp) => {
                let updatedGroup = { ...group };
                let index = updatedGroup.members.findIndex((m) => m.number === data.number);

                if (index !== -1) {
                    updatedGroup.members[index] = {
                        ...updatedGroup.members[index],
                        memberInfo: {
                            ...updatedGroup.members[index].memberInfo,
                            outboundNumber: resp.data.associatedNumber,
                        },
                    };

                    setGroup(updatedGroup);
                }

                setSavingOutbound(false);
            })
            .catch((err) => {
                console.log(err);
                setSavingOutbound(false);
            });
    };

    const handleUsersSaved = (response) => {
        let updatedUsers = [...group.members];

        response.forEach((d) => {
            if (d.addToGroup && !updatedUsers.find((uu) => uu.number === d.number)) {
                updatedUsers.push(d);
            } else if (!d.addToGroup && updatedUsers.find((uu) => uu.number === d.number)) {
                let index = updatedUsers.findIndex((uu) => uu.number === d.number);

                updatedUsers.splice(index, 1);
            }
        });

        setGroup({
            ...group,
            members: updatedUsers,
        });

        setShowPopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <div className='button-37 border ml-2 d-flex justify-content-center align-items-center clickable' onClick={handleEditUsersClicked}>
            <i className='fas fa-cogs text-primary' />
        </div>
    );

    let data = group?.members?.map((m) => {
        return {
            ...m,
            username: `${m?.memberInfo.firstName} ${m?.memberInfo.lastName}`,
            present: m.presence === 0,
            sentOutboundNumber: group.didNumber ? m?.memberInfo.outboundNumber === group.didNumber.number : null,
            outboundNumber: m?.memberInfo.outboundNumber,
        };
    });

    let noItemsContent = <Loading title={<Translate id='groupMembership.group.overview.loading' />} description={<Translate id='groupMembership.group.overview.loadingDesc' />} />;

    if (group && (!group.members || group.members.length === 0)) {
        noItemsContent = (
            <NoContent
                title={<Translate id='groupMembership.group.overview.noEntries' />}
                description={<Translate id='groupMembership.group.overview.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-users-slash fa-7x'
            />
        );
    }

    const config = {
        columns: {
            username: {
                label: <Translate id='groupMembership.group.overview.username' />,
            },
            present: {
                label: <Translate id='groupMembership.group.overview.present' />,
                component: SwitchWrapper,
                props: {
                    checked: 'present',
                    data: (data) => data,
                    disabled: () => savingPresence,
                    onChange: () => handlePresenceChanged,
                },
                execute: ['data', 'disabled', 'onChange'],
            },
            sendOutboundNumber: {
                label: <Translate id='groupMembership.group.overview.sentOutboundNumber' />,
                component: SwitchWrapper,
                props: {
                    checked: 'sentOutboundNumber',
                    data: (data) => data,
                    disabled: () => savingOutbound,
                    onChange: () => handleOutboundChanged,
                },
                execute: ['data', 'disabled', 'onChange'],
            },
            outboundNumber: {
                label: <Translate id='groupMembership.group.overview.outboundNumber' />,
            },
        },
        search: {
            componentsAfter: componentsAfter,
        },
        noItemsComponent: noItemsContent,
        data: data,
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let titleLabel = <Translate id='groupMembership.group.overview.title' />;
    if (group) {
        titleLabel = (
            <>
                <Translate id='groupMembership.group.overview.title' />: {group.name}
            </>
        );
    }

    let userPopupContent = null;

    if (showPopup) {
        userPopupContent = (
            <UserModal
                title={
                    <>
                        <Translate id='groupMembership.group.users.title' />: {group?.name}
                    </>
                }
                userIds={group && group.members ? group.members.map((m) => m.number) : []}
                failed={<Translate id='groupMembership.group.users.failed' />}
                titleBarColor='bg-primary'
                confirmClasses={['bg-success', 'text-white']}
                onClose={() => setShowPopup(null)}
                onSave={handleUsersSaved}
                group={group}
            />
        );
    }

    return (
        <>
            {userPopupContent}
            <div className='mt-3'>
                <div className='box-shadow'>
                    <TitleBar label={titleLabel} />
                </div>
                <ManageTable {...config} />
            </div>
        </>
    );
};

export default GroupOverview;
