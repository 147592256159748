import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Translate } from '@hanssens/ht-translate';

import UserItem from './UserItem';

const UsersContainer = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { users, selectedUsers, id, toggleSelectionInGroup, toggleSelection, multiSelectTo, disabled, filter } = props;
    const [saving, setSaving] = useState(false);

    /***********************************************************************
     * Render
    /***********************************************************************/
    if (!users) {
        return <Translate id='groupMembership.user.users.noGroups' />;
    }

    let content = users.columns[id].itemIds.map((itemId, index) => {
        let u = users.items.find((g) => g.number === itemId);
        let found = selectedUsers.findIndex((sg) => sg === itemId) !== -1;

        if (u.firstName.toLowerCase().includes(filter.toLowerCase()) || u.lastName.toLowerCase().includes(filter.toLowerCase()) || u.number.includes(filter)) {
            return (
                <Draggable key={u.number} draggableId={u.number} index={index} isDragDisabled={disabled}>
                    {(provided, snapshot) => (
                        <UserItem
                            containerId={id}
                            provided={provided}
                            snapshot={snapshot}
                            user={u}
                            found={found}
                            selectionCount={selectedUsers.length}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            setSaving={setSaving}
                            saving={saving}
                        />
                    )}
                </Draggable>
            );
        }

        return null;
    });

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex flex-row mb-1'>
                {props.title}
                {props.componentsAfter}
            </div>

            <Droppable key={id} droppableId={id}>
                {(provided) => (
                    <div className='bg-light h-100 scrollable-y p-2' ref={provided.innerRef} {...provided.droppableProps}>
                        {content}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default UsersContainer;
