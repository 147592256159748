import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NoContent } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';

// GENERAL
import Groups from '../pages/General/Groups/Groups';
import GroupOverview from '../components/Groups/GroupOverview';

import groupTranslations from '../translations/group/group.json';

// Css
import '../css/Group/group.scss';

const GroupRoutes = (props) => {
    useTranslate(groupTranslations);

    return (
        <Routes>
            <Route path='/' element={<Groups />}>
                <Route
                    index
                    element={
                        <div className='mt-3'>
                            <NoContent
                                title={<Translate id='groupMembership.group.search.noContent.title' />}
                                description={<Translate id='groupMembership.group.search.noContent.description' />}
                                icon='fas fa-user-group fa-7x'
                                backgroundColour='bg-primary'
                            />
                        </div>
                    }
                />
                <Route path={':number'} element={<GroupOverview />} />
            </Route>
        </Routes>
    );
};

export default GroupRoutes;
