import React from 'react';
import Content from '../../routes/MainRoutes';

import './ContentContainer.scss';
import SnackbarHandler from '../../../library/components/Global/SnackbarHandler';

const ContentContainer = (props) => {
    // Set the base classes
    let classNames = ['content-container'];
    let snackbarClassNames = [];

    // Add left toggle
    if (props.leftToggled) {
        classNames.push('left-toggled');
    }

    // Add right toggle
    if (props.rightToggled) {
        classNames.push('right-toggled');
        snackbarClassNames.push('snackbar-right-toggled');
    }

    // Render content
    return (
        <div id='content-container' className={classNames.join(' ')}>
            <SnackbarHandler className={snackbarClassNames.join(' ')} />
            <Content />
        </div>
    );
};

export default ContentContainer;
