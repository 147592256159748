import { createAxiosInstance as createGroupMembershipApi } from '../api/groupMembershipApi';
import { createAxiosInstance as createRightsApi } from '../api/rightsApi';

// Default configuration
const defaultConfiguration = {
    // Base props
    // API_URI: 'https://localhost:7182/',
    // RIGHTS_URI: 'https://localhost:44383/',
    RIGHTS_URI: 'https://api.hanssens.io/',
    OIDC_BASE_URI: 'https://login.hanssens.io',
    OIDC_CLIENT_ID: 'group.membership.dev',
    HT_APPID: 'c3c985c0-d47a-4276-b8cb-adbb09ac5e0c',
};

// Actual configuration
let config = { ...defaultConfiguration };

// Get the configuration
export const getConfig = () => {
    return { ...config };
};

// Set the configuration
// this must be done at the index.js
export const initialiseConfig = (newConfig) => {
    // Update config
    config = {
        ...defaultConfiguration,
        ...newConfig,
    };

    // Change the base url with the new updated config
    createGroupMembershipApi(config);
    createRightsApi(config);
};

export const tenantIdChanged = (tenantId) => {
    // Update config
    config = {
        ...config,
        SELECTED_HT_TENANTID: tenantId,
    };

    // Change the api with the new updated config
    createRightsApi(config);
};

export const groupMembershipAPIChanged = (uri) => {
    // Update config
    config = {
        ...config,
        API_URI: uri,
    };

    // Change the api with the new updated config
    createGroupMembershipApi(config);
};
