import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NoContent } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';

// GENERAL
import Users from '../pages/General/Users/Users';
import UserOverview from '../components/Users/UserOverview';

import userTranslations from '../translations/user/user.json';

// Css
import '../css/User/user.scss';

const UserRoutes = (props) => {
    useTranslate(userTranslations);
    return (
        <Routes>
            {/* General */}
            <Route path='/' element={<Users />}>
                <Route
                    index
                    element={
                        <div className='mt-3'>
                            <NoContent
                                title={<Translate id='groupMembership.user.search.noContent.title' />}
                                description={<Translate id='groupMembership.user.search.noContent.description' />}
                                icon='fas fa-user fa-7x'
                                backgroundColour='bg-primary'
                            />
                        </div>
                    }
                />
                <Route path=':number' element={<UserOverview />} />
            </Route>
        </Routes>
    );
};

export default UserRoutes;
