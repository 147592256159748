import React from 'react';
import { PageTitle } from 'ht-gui';
import { Translate, useTranslate } from '@hanssens/ht-translate';

import reportTranslations from '../../../translations/report/report.json';

// Css
import '../../../css/Report/report.scss';
import ReportOverview from '../../../components/Report/ReportOverview';

const Report = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/

    useTranslate(reportTranslations);

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    return (
        <section id='report' className='p-4 scrollable-y'>
            <PageTitle title={<Translate id='groupMembership.report.title' />} subTitle={<Translate id='groupMembership.report.subTitle' />} />

            <ReportOverview />
        </section>
    );
};

export default Report;
