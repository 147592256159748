import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import withResize from 'ht-resize';

import { MainRouter as GroupMembershipRouter, routerPrefix as groupMembershipPrefix } from '../../library/index';

const MainRoutes = () => {
    return (
        <Routes>
            {/* General */}
            <Route path={groupMembershipPrefix + '/*'} element={<GroupMembershipRouter />} />

            {/* REDIRECT */}
            <Route path='*' element={<Navigate to={groupMembershipPrefix} replace />} />
        </Routes>
    );
};

export default withResize()(MainRoutes);
