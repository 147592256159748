import React, { useState } from 'react';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';
import { Switch } from 'ht-gui';

import groupMembershipApi from '../../../api/groupMembershipApi';

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};

const UserGroupItem = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { provided, snapshot, group, found, selectionCount, toggleSelectionInGroup, multiSelectTo, toggleSelection, saving, setSaving } = props;

    const [draggingGroupId] = useStore('groupMembership-draggingGroup');
    const [user, setUser] = useStore('groupMembership-singleUser');
    const [, setSnackbar] = useStore('snackbarHandler-snackbar');

    const [showDropDownInfo, setShowDropDownInfo] = useState(false);

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const getClassNames = (isDragging, found) => {
        let ghosting = found && draggingGroupId && draggingGroupId !== group.number;
        let classNames = ['noselect', 'mx-1 p-2 text-white clickable small-shadow'];

        if (isDragging || found) {
            classNames.push('bg-secondary');
        } else {
            classNames.push('bg-dark');
        }

        if (ghosting) {
            classNames.push('ghost');
        }

        return classNames.join(' ');
    };

    const wasToggleInSelectionGroupKeyUsed = (event) => {
        const isUsingWindows = navigator?.userAgentData?.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    const wasMultiSelectKeyUsed = (event) => event.shiftKey;

    const performAction = (event) => {
        if (wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(group.number);
            return;
        }

        if (wasMultiSelectKeyUsed(event)) {
            multiSelectTo(group.number);
            return;
        }

        toggleSelection(group.number);
    };

    // Using onClick as it will be correctly
    // preventing if there was a drag
    const handleOnClick = (event) => {
        if (event.target?.className?.includes('MuiSwitch-input')) {
            return;
        }

        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }

        // marking the event as used
        event.preventDefault();

        performAction(event);
    };

    const onTouchEnd = (event) => {
        if (event.defaultPrevented) {
            return;
        }

        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        event.preventDefault();
        toggleSelectionInGroup(group.number);
    };

    const onKeyDown = (event, provided, snapshot) => {
        if (event.defaultPrevented) {
            return;
        }

        if (snapshot.isDragging) {
            return;
        }

        if (event.keyCode !== keyCodes.enter) {
            return;
        }

        // we are using the event for selection
        event.preventDefault();

        performAction(event);
    };

    const hanldeDropDownClicked = (event) => {
        event.preventDefault();
        setShowDropDownInfo(!showDropDownInfo);
    };

    const handleToggleChanged = (event) => {
        if (!user) {
            return;
        }

        let associatedNumber = {
            associatedType: 7,
            directoryNumber: user.userName,
            billingNumber: '',
            oldValue: user.outboundNumber,
            userNumber: user.userName,
        };

        if (event.target.checked) {
            associatedNumber = {
                ...associatedNumber,
                associatedNumber: group.didNumber.number,
            };
        } else {
            associatedNumber = {
                ...associatedNumber,
                associatedNumber: user.inboundNumber,
            };
        }

        setSaving(true);
        groupMembershipApi()
            .put(`v1/associatedNumber/${user.userName}`, associatedNumber)
            .then((resp) => {
                setUser({
                    ...user,
                    outboundNumber: resp.data.associatedNumber,
                });
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.saved' />,
                    severity: 'success',
                    translate: false,
                });
                setSaving(false);
            })
            .catch((err) => {
                console.log(err);
                setSnackbar({
                    open: true,
                    text: <Translate id='groupMembership.user.snackbar.errorOutboundSave' />,
                    severity: 'error',
                    translate: false,
                });
                setSaving(false);
                return;
            });
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let selectionCountContent = null;

    if (selectionCount > 1 && snapshot.isDragging) {
        selectionCountContent = <div className='d-flex bg-primary text-white align-items-center justify-content-center selection-count'>{selectionCount}</div>;
    }

    let content = (
        <div className={getClassNames(snapshot.isDragging, found)}>
            {group.name} ({group.number})
        </div>
    );

    if (group) {
        let dropDownContent = <Translate id='groupMembership.user.group.item.noOutboundNumber' />;

        if (group.didNumber) {
            dropDownContent = (
                <>
                    <Translate id='groupMembership.user.group.item.outboundNumber' />: {group.didNumber.number}
                </>
            );
        }

        content = (
            <div className=''>
                <div className={getClassNames(snapshot.isDragging, found)}>
                    <div className='d-flex flex-row align-items-center'>
                        {showDropDownInfo ? (
                            <div className='d-flex p-2 mr-2' onClick={hanldeDropDownClicked}>
                                <i className='fas fa-caret-down' />
                            </div>
                        ) : (
                            <div className='d-flex p-2 mr-2' onClick={hanldeDropDownClicked}>
                                <i className='fas fa-caret-right' />
                            </div>
                        )}
                        {group.name} ({group.number})
                        <div className='ml-auto d-flex flex-row align-items-center'>
                            <i className='mr-3 fas fa-phone' />
                            {group.didNumber ? <Switch checked={group.checked} onChange={handleToggleChanged} disabled={saving} /> : null}
                        </div>
                    </div>
                </div>
                {showDropDownInfo ? <div className='mx-1 p-2 text-black bg-white'>{dropDownContent}</div> : null}
            </div>
        );
    }

    return (
        <div
            className='group-item my-1'
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={handleOnClick}
            onTouchEnd={onTouchEnd}
            onKeyDown={(event) => onKeyDown(event, provided, snapshot)}>
            {selectionCountContent}
            {content}
        </div>
    );
};

export default UserGroupItem;
