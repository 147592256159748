import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Translate } from '@hanssens/ht-translate';

import UserGroupItem from '../UserGroupItem';

const GroupsContainer = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { groups, selectedGroups, id, toggleSelectionInGroup, toggleSelection, multiSelectTo, disabled, filter } = props;
    const [saving, setSaving] = useState(false);

    /***********************************************************************
     * Render
    /***********************************************************************/
    if (!groups) {
        return <Translate id='groupMembership.user.groups.noGroups' />;
    }

    let content = groups.columns[id].itemIds.map((itemId, index) => {
        let g = groups.items.find((g) => g.number === itemId);
        let found = selectedGroups.findIndex((sg) => sg === itemId) !== -1;

        if (g.name.toLowerCase().includes(filter.toLowerCase()) || g.number.includes(filter)) {
            return (
                <Draggable key={g.number} draggableId={g.number} index={index} isDragDisabled={disabled}>
                    {(provided, snapshot) => (
                        <UserGroupItem
                            containerId={id}
                            provided={provided}
                            snapshot={snapshot}
                            group={g}
                            found={found}
                            selectionCount={selectedGroups.length}
                            toggleSelectionInGroup={toggleSelectionInGroup}
                            toggleSelection={toggleSelection}
                            multiSelectTo={multiSelectTo}
                            setSaving={setSaving}
                            saving={saving}
                        />
                    )}
                </Draggable>
            );
        }

        return null;
    });

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex flex-row mb-1'>
                {props.title}
                {props.componentsAfter}
            </div>

            <Droppable key={id} droppableId={id}>
                {(provided) => (
                    <div className='bg-light h-100 scrollable-y p-2' ref={provided.innerRef} {...provided.droppableProps}>
                        {content}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default GroupsContainer;
