import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { TranslateProvider } from '@hanssens/ht-translate';
import { configureStore } from 'ht-store';
import { reducer as oidcReducer, loadUser, OidcProvider } from 'redux-oidc';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { initialiseConfig as initialiseGroupMembershipConfig } from './library/index';

// Import user manager config
import userManager from './configs/userManager';

// Import root application
import AuthBeforeApp from './app/components/Auth/AuthBeforeApp';

// Stores
import { storeConfig as groupMembershipStoreConfig } from './library/index';

// Import css
import '@fortawesome/fontawesome-pro/css/all.css';
import './app/css/main.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};

// Apply middleware
const enhancer = composeEnhancers(applyMiddleware(thunk));

// Combine reducers
const rootReducer = combineReducers({
    oidc: oidcReducer,
});

// Create the store
const store = createStore(rootReducer, initialState, enhancer);

// Setup the authentication store
loadUser(store, userManager);

initialiseGroupMembershipConfig();

// Create app
const app = (
    <React.StrictMode>
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <TranslateProvider languages={['en', 'nl']}>
                    <BrowserRouter>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <AuthBeforeApp />
                            {/* <App /> */}
                        </MuiPickersUtilsProvider>
                    </BrowserRouter>
                </TranslateProvider>
            </OidcProvider>
        </Provider>
    </React.StrictMode>
);

const storeConfig = {
    // Temporary local snackbar store
    'snackbarHandler-snackbar': {
        initialValue: null,
    },
    auth: {
        initialValue: {
            isAuthenticated: false,
            rights: null,
            error: null,
        },
        persist: false,
    },
    tenant: {
        initialValue: {
            selectedTenant: null,
        },
        persist: true,
    },
    appSetting: {
        initialValue: null,
        persist: true,
    },

    ...groupMembershipStoreConfig,
};

configureStore(storeConfig);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
