import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';

// Import components
import Topbar from './Topbar';
import SidebarLeft from './SidebarLeft';
// import SidebarRight from './SidebarRight';
import ContentContainer from './ContentContainer';

// Initial state for toggled statuses
// If you only want the left sidebar, you should remove the sidebar right logic, including here.
const initialToggled = {
    sidebarLeft: true,
    // sidebarRight: true,
    topbar: false,
    size: 1,
};

const Layout = () => {
    /********************************/
    /* LOCAL STATE & PROPS 
    /********************************/
    const container = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [toggled, setToggled] = useState(initialToggled);

    /********************************/
    /* FUNCTIONS 
    /********************************/
    // Initial useEffect that sets the toggled statuses
    // based on the width of the main page.
    useEffect(() => {
        // Save the width
        const width = container.current.clientWidth;

        // Initialise toggled property (Init for full screen desktop)
        let updateToggled = { ...initialToggled };

        // Change toggle based on width
        /*
         * Remove sidebar right logic if you do not want to use the right sidebar.
         * In that case, only the sidebar left is important.
         */
        if (width >= 1200) {
            // Extra large screens (XL)
            updateToggled.sidebarLeft = true;
            // updateToggled.sidebarRight = true;
        } else if (width >= 577) {
            // Medium or Large screens (MD or LG)
            updateToggled.sidebarLeft = true;
            // updateToggled.sidebarRight = false;
        } else {
            updateToggled.sidebarLeft = false;
            // updateToggled.sidebarRight = false;
        }

        // Set size based on width
        // This width is for medium to large
        // this allows us to switch logic from having both sidebars open (>992)
        // to only being able to have one of the two open (<992)
        if (width >= 992) {
            updateToggled.size = 1;
        }

        // Set toggled
        setToggled(updateToggled);
    }, []);

    // Function that handles when the left toggle button is clicked.
    /*
     * If you don't want to have a left sidebar, remove this function
     * If you don't want to have a right sidebar, edit this function:
     * - remove the logic that toggles the right sidebar when you toggle the left sidebar
     *   based on the current size of the container (The 'edit right if necessary' part)
     */
    const handleToggleLeftClicked = () => {
        // Initialise right & toggle left
        let left = !toggled.sidebarLeft;
        // let right = toggled.sidebarRight;

        // Edit right if necessary
        // if ((toggled.size === 0) & (left === true) && right === true) {
        //     right = false;
        // }

        // Update
        setToggled({
            ...toggled,
            sidebarLeft: left,
            // sidebarRight: right,
        });
    };

    // Function that handles when the right toggle button is clicked.
    /*
     * If you don't want to have a right sidebar, remove this function
     * If you don't want to have a left sidebar, edit this function:
     * - Remove the logic that toggles the left sidebar when you toggle the right sidebar
     *   based on the current size of the container (The 'edit left if necessary' part)
     */
    // const handleToggleRightClicked = () => {
    //     // Initialise left & toggle right
    //     let left = toggled.sidebarLeft;
    //     let right = !toggled.sidebarRight;

    //     // Edit left if necessary
    //     if (toggled.size === 0 && left === true && right === true) {
    //         left = false;
    //     }

    //     // Update
    //     setToggled({
    //         ...toggled,
    //         sidebarLeft: left,
    //         sidebarRight: right,
    //     });
    // };

    // Function that handles when the topbar is toggled
    /*
     * If you don't want any toggles in the topbar (or don't want a topbar), remove this function
     */
    const handleToggleTopbarClicked = () => {
        setToggled({
            ...toggled,
            topbar: !toggled.topbar,
        });
    };

    // Function that fires when the container is resized
    // This function will change the 'size' property of the toggled state
    // based on the new width of the container.
    // A re-render will only occur when the 'size' property changes.
    /*
     * The right sidebar will be collapsed (toggled.sidebarRight set to false) when the size
     * changes to 0 and both sidebars are currently 'toggled' or visible.
     * If you don't want a right sidebar, or only have a right sidebar, remove that part of the logic
     */
    const handleResize = (width) => {
        // Verify width
        if (width < 992) {
            // Size must be 0
            if (toggled.size !== 0) {
                // Logic to collapse right sidebar if both sidebars are toggled
                // if (toggled.sidebarLeft && toggled.sidebarRight) {
                //     setToggled({
                //         ...toggled,
                //         sidebarRight: false,
                //         size: 0,
                //     });
                // }
                // Sidebars aren't both toggled, so execute default logic
                // else {
                setToggled({
                    ...toggled,
                    size: 0,
                });
                // }
            }
        } else {
            // Size must be 1
            if (toggled.size !== 1) {
                setToggled({
                    ...toggled,
                    size: 1,
                });
            }
        }
    };

    // Function that handles when a link was clicked in the left sidebar,
    // where the navigation logic is currently managed.
    const handleLinkClicked = (link) => {
        if (location.pathname === link) {
            return;
        }

        navigate(link);
    };

    /********************************/
    /* RENDER 
    /********************************/
    return (
        <ReactResizeDetector handleWidth onResize={handleResize} targetRef={container}>
            <div ref={container}>
                <Topbar
                    topbarToggled={toggled.topbar}
                    sidebarRightToggled={toggled.sidebarRight}
                    handleToggleLeftClicked={handleToggleLeftClicked}
                    // handleToggleRightClicked={handleToggleRightClicked}
                    handleToggleTopbarClicked={handleToggleTopbarClicked}
                />

                <SidebarLeft toggled={toggled.sidebarLeft} handleLinkClicked={handleLinkClicked} />

                {/* The main content container. Width of this is changed based on the toggled states. Go deeper inside to add content */}
                <ContentContainer leftToggled={toggled.sidebarLeft} rightToggled={toggled.sidebarRight} />

                {/* <SidebarRight toggled={toggled.sidebarRight} topbarToggled={toggled.topbar} /> */}
            </div>
        </ReactResizeDetector>
    );
};

export default Layout;
