import React from 'react';
import { useStore } from 'ht-store';

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};

const UserGroupItem = (props) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const { provided, snapshot, user, found, selectionCount, toggleSelectionInGroup, multiSelectTo, toggleSelection } = props;

    const [draggingUserId] = useStore('groupMembership-draggingUser');

    /***********************************************************************
     * Functions
    /***********************************************************************/
    const getClassNames = (isDragging, found) => {
        let ghosting = found && draggingUserId && draggingUserId !== user.number;
        let classNames = ['noselect', 'mx-1 p-2 text-white clickable small-shadow'];

        if (isDragging || found) {
            classNames.push('bg-secondary');
        } else {
            classNames.push('bg-dark');
        }

        if (ghosting) {
            classNames.push('ghost');
        }

        return classNames.join(' ');
    };

    const wasToggleInSelectionGroupKeyUsed = (event) => {
        const isUsingWindows = navigator?.userAgentData?.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    const wasMultiSelectKeyUsed = (event) => event.shiftKey;

    const performAction = (event) => {
        if (wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(user.number);
            return;
        }

        if (wasMultiSelectKeyUsed(event)) {
            multiSelectTo(user.number);
            return;
        }

        toggleSelection(user.number);
    };

    // Using onClick as it will be correctly
    // preventing if there was a drag
    const handleOnClick = (event) => {
        if (event.target?.className?.includes('MuiSwitch-input')) {
            return;
        }

        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }

        // marking the event as used
        event.preventDefault();

        performAction(event);
    };

    const onTouchEnd = (event) => {
        if (event.defaultPrevented) {
            return;
        }

        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        event.preventDefault();
        toggleSelectionInGroup(user.number);
    };

    const onKeyDown = (event, provided, snapshot) => {
        if (event.defaultPrevented) {
            return;
        }

        if (snapshot.isDragging) {
            return;
        }

        if (event.keyCode !== keyCodes.enter) {
            return;
        }

        // we are using the event for selection
        event.preventDefault();

        performAction(event);
    };

    /***********************************************************************
     * Render
    /***********************************************************************/

    let selectionCountContent = null;

    if (selectionCount > 1 && snapshot.isDragging) {
        selectionCountContent = <div className='d-flex bg-primary text-white align-items-center justify-content-center selection-count'>{selectionCount}</div>;
    }

    let content = (
        <div className={getClassNames(snapshot.isDragging, found)}>
            {user.firstName} {user.lastName} ({user.number})
        </div>
    );

    return (
        <div
            className='user-item my-1'
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={handleOnClick}
            onTouchEnd={onTouchEnd}
            onKeyDown={(event) => onKeyDown(event, provided, snapshot)}>
            {selectionCountContent}
            {content}
        </div>
    );
};

export default UserGroupItem;
