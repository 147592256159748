import React from 'react';

const DropDownButton = (props) => {
    let pointerClasses = ['fas', 'fa-caret-up', 'fa-2x', 'pointer', props.colour];
    let iconClasses = ['fa-2x', 'mx-auto', props.icon, props.text];

    if (props.active === props.type) {
        pointerClasses.push('show');
    }

    return (
        <div onClick={() => props.handleTopbarMenuClicked(props.type)} className='bg-white item-right ml-auto d-flex align-items-center clickable'>
            <i className={iconClasses.join(' ')}></i>
            <i className={pointerClasses.join(' ')}></i>
        </div>
    );
};

export default DropDownButton;
