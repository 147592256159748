import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';
import { SaveButton, TitleBar } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';

import { default as initial, right, left } from './dragAndDropData';

import GroupDragNDrop from './GroupModal/GroupDragNDrop';
import groupMembershipApi from '../../../api/groupMembershipApi';

const GroupModal = ({ title, userGroupIds, failed, confirmLabel, titleBarColor, confirmClasses, confirmingLabel, onClose, onSave }) => {
    const [error, setError] = useState(false);
    const [groups, setGroups] = useState(initial);
    const [saving, setSaving] = useState(false);
    const [user] = useStore('groupMembership-user');

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleSaveClicked = (onSuccess, onError) => {
        setSaving(true);
        setError(false);

        let groupData = [];

        // Add new ones
        groups.columns[right.id].itemIds.forEach((i) => {
            if (!userGroupIds.includes(i)) {
                let item = groups.items.find((g) => g.number === i);

                if (item) {
                    let data = {
                        addToGroup: true,
                        presence: 1,
                        number: user.number,
                        ringGroup: item.number,
                        ringGroupName: item.name,
                        userName: `${user.firstName} ${user.lastName}`,
                        userNumber: user.number,
                    };

                    groupData.push(data);
                }
            }
        });

        // Remove old ones
        groups.columns[left.id].itemIds.forEach((i) => {
            if (userGroupIds.includes(i)) {
                let item = groups.items.find((g) => g.number === i);

                if (item) {
                    let data = {
                        presence: 1,
                        number: user.number,
                        ringGroup: item.number,
                        ringGroupName: item.name,
                        userName: `${user.firstName} ${user.lastName}`,
                        userNumber: user.number,
                    };

                    groupData.push(data);
                }
            }
        });

        groupMembershipApi()
            .post('v1/userGroupMember', groupData)
            .then((resp) => {
                onSave(resp);
                setSaving(false);
                onSuccess();
            })
            .catch((err) => {
                console.log('Error: ', error);
                onError();
                setSaving(false);
                setError(true);
            });
    };

    const handleCancelClicked = () => {
        onClose();
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    if (!titleBarColor) {
        titleBarColor = 'bg-primary';
    }

    let saveButtonClasses = [];
    if (!confirmClasses) {
        saveButtonClasses = ['bg-primary', 'text-white'];
    } else {
        if (Array.isArray(confirmClasses)) {
            saveButtonClasses = [...confirmClasses];
        } else {
            saveButtonClasses = [confirmClasses];
        }
    }

    return (
        <Modal
            open
            onClose={onClose}
            aria-labelledby='groupmembership-groups'
            aria-describedby='Groupmembership groups popup'
            className='d-flex align-items-center justify-content-center'>
            <div
                id='group-membership-groups-popup'
                className={getClasses({ shared: 'd-flex flex-column bg-white outline-0', xs: 'w-75', md: 'w-50' })}>
                {/* Title */}
                <TitleBar
                    color={titleBarColor}
                    label={title}
                    componentsAfter={
                        <div className='ml-auto d-flex align-items-center justify-content-center clickable' onClick={onClose}>
                            <i className='fas fa-times'></i>
                        </div>
                    }
                />

                {/* Description */}
                <GroupDragNDrop userGroupIds={userGroupIds} groups={groups} setGroups={setGroups} saving={saving} />

                {error ? <div className='alert alert-danger rounded-0 mx-3'>{failed}</div> : null}

                <div className='mt-auto ml-auto mr-3 mb-3'>
                    <SaveButton
                        showFeedback
                        onSave={handleSaveClicked}
                        onCancel={handleCancelClicked}
                        saveContent={confirmLabel ? confirmLabel : <Translate id='groupMembership.user.confirm' />}
                        saveClasses={saveButtonClasses.join(' ')}
                        savingContent={confirmingLabel ? confirmingLabel : <Translate id='groupMembership.user.saving' />}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default GroupModal;
