import { invariant } from './invariant';
import reorder from './reorder';

const withNewitemIds = (column, itemIds) => ({
    id: column.id,
    title: column.title,
    itemIds: itemIds,
});

const reorderSingleDrag = ({ entities, selectedItemIds, source, destination }) => {
    // moving in the same list
    if (source.droppableId === destination.droppableId) {
        const column = entities.columns[source.droppableId];
        const reordered = reorder(column.itemIds, source.index, destination.index);

        const updated = {
            ...entities,
            columns: {
                ...entities.columns,
                [column.id]: withNewitemIds(column, reordered),
            },
        };

        return {
            entities: updated,
            selectedItemIds,
        };
    }

    // moving to a new list
    const home = entities.columns[source.droppableId];
    const foreign = entities.columns[destination.droppableId];

    // the id of the task to be moved
    const itemId = home.itemIds[source.index];

    // remove from home column
    const newHomeitemIds = [...home.itemIds];
    newHomeitemIds.splice(source.index, 1);

    // add to foreign column
    const newForeignitemIds = [...foreign.itemIds];
    newForeignitemIds.splice(destination.index, 0, itemId);

    const updated = {
        ...entities,
        columns: {
            ...entities.columns,
            [home.id]: withNewitemIds(home, newHomeitemIds),
            [foreign.id]: withNewitemIds(foreign, newForeignitemIds),
        },
    };

    return {
        entities: updated,
        selectedItemIds,
    };
};

export const getHomeColumn = (entities, itemId) => {
    const column = Object.entries({ ...entities.columns }).find((entry) => {
        return entry[1].itemIds.includes(itemId);
    });
    invariant(column[0], 'Count not find column');

    return { ...column[1] };
};

const reorderMultiDrag = ({ entities, selectedItemIds, source, destination }) => {
    const start = { ...entities.columns[source.droppableId] };
    const dragged = [...start.itemIds[source.index]];

    const insertAtIndex = (() => {
        const destinationIndexOffset = selectedItemIds.reduce((previous, current) => {
            if (current === dragged) {
                return previous;
            }

            const final = entities.columns[destination.droppableId];
            const column = getHomeColumn(entities, current);

            if (column !== final) {
                return previous;
            }

            const index = column.itemIds.indexOf(current);

            if (index >= destination.index) {
                return previous;
            }

            // the selected item is before the destination index
            // we need to account for this when inserting into the new location
            return previous + 1;
        }, 0);

        const result = destination.index - destinationIndexOffset;
        return result;
    })();

    // doing the ordering now as we are required to look up columns
    // and know original ordering
    const orderedselectedItemIds = [...selectedItemIds];
    orderedselectedItemIds.sort((a, b) => {
        // moving the dragged item to the top of the list
        if (a === dragged) {
            return -1;
        }
        if (b === dragged) {
            return 1;
        }

        // sorting by their natural indexes
        const columnForA = getHomeColumn(entities, a);
        const indexOfA = columnForA.itemIds.indexOf(a);
        const columnForB = getHomeColumn(entities, b);
        const indexOfB = columnForB.itemIds.indexOf(b);

        if (indexOfA !== indexOfB) {
            return indexOfA - indexOfB;
        }

        // sorting by their order in the selectedItemIds list
        return -1;
    });

    // we need to remove all of the selected tasks from their columns
    const withRemovedItems = Object.entries({ ...entities.columns }).reduce(
        (previous, column) => {
            // remove the id's of the items that are selected
            const remainingitemIds = { ...column[1] }.itemIds.filter((id) => !selectedItemIds.includes(id));

            previous[column[0]] = withNewitemIds({ ...column[1] }, remainingitemIds);
            return previous;
        },
        { ...entities.columns }
    );

    const final = withRemovedItems[destination.droppableId];
    const withInserted = (() => {
        const base = [...final.itemIds];
        base.splice(insertAtIndex, 0, ...orderedselectedItemIds);
        return base;
    })();

    // insert all selected tasks into final column
    const withAddedTasks = {
        ...withRemovedItems,
        [final.id]: withNewitemIds(final, withInserted),
    };

    const updated = {
        ...entities,
        columns: withAddedTasks,
    };

    return {
        entities: updated,
        selectedItemIds: orderedselectedItemIds,
    };
};

export const multiDragAwareReorder = (args) => {
    if (args.selectedItemIds.length > 1) {
        return reorderMultiDrag(args);
    }
    return reorderSingleDrag(args);
};

export const multiSelectTo = (entities, selectedItemIds, newitemId) => {
    // Nothing already selected
    if (!selectedItemIds.length) {
        return [newitemId];
    }

    const columnOfNew = getHomeColumn(entities, newitemId);
    const indexOfNew = columnOfNew.itemIds.indexOf(newitemId);

    const lastSelected = selectedItemIds[selectedItemIds.length - 1];
    const columnOfLast = getHomeColumn(entities, lastSelected);
    const indexOfLast = columnOfLast.itemIds.indexOf(lastSelected);

    // multi selecting to another column
    // select everything up to the index of the current item
    if (columnOfNew.id !== columnOfLast.id) {
        return columnOfNew.itemIds.slice(0, indexOfNew + 1);
    }

    // multi selecting in the same column
    // need to select everything between the last index and the current index inclusive

    // nothing to do here
    if (indexOfNew === indexOfLast) {
        return null;
    }

    const isSelectingForwards = indexOfNew > indexOfLast;
    const start = isSelectingForwards ? indexOfLast : indexOfNew;
    const end = isSelectingForwards ? indexOfNew : indexOfLast;

    const inBetween = columnOfNew.itemIds.slice(start, end + 1);

    // everything inbetween needs to have it's selection toggled.
    // with the exception of the start and end values which will always be selected

    const toAdd = inBetween.filter((itemId) => {
        // if already selected: then no need to select it again
        if (selectedItemIds.includes(itemId)) {
            return false;
        }
        return true;
    });

    const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
    const combined = [...selectedItemIds, ...sorted];

    return combined;
};
